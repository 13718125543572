import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Class from 'classnames';
import React from 'react';
import { getFaIconProps } from '../gears/helpers/fa';
import styles from './SidebarItem.scss';

export interface ISidebarItem {
    href: string;
    target?: string;
    icon?: string;
    open: boolean;
    active?: boolean;
    colour?: string | string[] | null;
    title: string;
    children: ISidebarItem[];
}

export interface ISidebarItemRef {
    hide: () => void;
    show: () => void;
}

interface ISidebarItemProps extends ISidebarItem {
    onClick: (sidebar: ISidebarItemRef) => void;
    onNavigate?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

/**
 * A single item or group of items in a sidebar
 */
function SidebarItem(props: ISidebarItemProps, ref: React.RefObject<ISidebarItemRef>): JSX.Element {
    const menuRef = React.useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState(props.open);
    React.useLayoutEffect(() => {
        if (props.open) {
            menuRef.current.classList.add(styles.open);
            setOpen(true);
        }
    }, [props.open]);

    const { children, icon, colour, title, active, target, href } = props;
    const faIcon = getFaIconProps(Class(icon, colour, 'icon'));

    React.useImperativeHandle(ref, () => ({
        hide: () => {
            menuRef.current.classList.remove(styles.open);
            setOpen(false);
        },
        show: () => {
            menuRef.current.classList.add(styles.open);
            setOpen(true);
        },
    }));

    const onClick = React.useCallback(() => {
        const menu = menuRef.current;
        if (menu) {
            menu.classList.toggle(styles.open);
        }
        if (props.onClick && ref.current) {
            props.onClick(ref.current);
        }
        setOpen(!open);
    }, [props, ref, open]);

    const onNavigate = React.useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (props.onNavigate) {
                props.onNavigate(e);
            }
        },
        [props],
    );

    // We have sub-items, make clicking this open them
    if (children && children.length) {
        return (
            <div className={Class(styles.item)}>
                <div ref={menuRef} className={Class(styles.menu)} onClick={onClick}>
                    <FontAwesomeIcon {...faIcon} />
                    <span>{title}</span>
                    <FontAwesomeIcon fixedWidth icon={open ? 'minus' : 'plus'} className={Class(styles.group)} />
                </div>
                <div className={Class(styles.submenu, { [styles.open]: open })} style={{ height: open ? children.length * 40 : 0 }}>
                    <div style={{ transform: `translateY(${open ? 0 : -children.length * 40}px)` }}>
                        {children.map((item: any, index: number) => {
                            const itemFA = item.icon ? getFaIconProps(item.icon + ' ' + Class(styles.icon)) : { className: Class(styles.empty, styles.icon), icon: 'circle' };
                            return (
                                <a key={index} href={item.href} target={item.target} data-href={item.href} onClick={onNavigate}>
                                    <div className={Class(styles.menu, { [styles.open]: item.open })}>
                                        <FontAwesomeIcon fixedWidth size="lg" {...itemFA} />
                                        <FontAwesomeIcon icon="circle" className={Class(styles.icon, styles.dot)} size="xs" />
                                        <span className={item.colour}>{item.title}</span>
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    } else {
        // We're a single item, click to go to URL
        return (
            <div className={Class(styles.item, styles['single-item'])}>
                <a href={href} target={target} data-href={href} onClick={onNavigate}>
                    <div ref={menuRef} className={Class(styles.menu, { open: active })} onClick={onClick}>
                        {faIcon.icon && <FontAwesomeIcon {...faIcon} />}
                        <span className={Class(colour)}>{title}</span>
                    </div>
                </a>
            </div>
        );
    }
}

export default React.memo(React.forwardRef(SidebarItem));
