import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Class from 'classnames';
import { Menu } from 'commonui/Menu';
import { observer } from 'mobx-react';
import { createTransformer } from 'mobx-utils';
import * as React from 'react';
import { bind } from '../../../node_modules/decko';
import { default as NotificationState, INotification as IStateNotification } from '../../gears/GearsState/NotificationState';
import { getFaIconProps, IFAIconProps } from '../../gears/helpers';
import Layer from '../../gears/helpers/FaLayer';
import ErrorDisplayComponent from '../ErrorDisplayComponent';
import relativeTime from '../Note/relativeTime';
import './NotificationMenu.scss';

interface INotification {
    id: number;
    title: string;
    content: string;
    icon?: IFAIconProps;
    extra?: string;
    unread: boolean;
    important?: boolean;
    hidden?: boolean;
    created_at: string;
}

interface INotificationMenuProps {
    notifications: NotificationState;
    className: string;
}

interface INotificationMenuState {
    hasChecked: boolean;
}

function toMenuNotification(notification: IStateNotification): INotification {
    const details = notification.details;
    return {
        content: details.content || (details as any).message,
        created_at: notification.created_at,
        icon: details.icon ? getFaIconProps(details.icon) : undefined,
        id: notification.id,
        title: details.title || '',
        unread: !notification.read,
    };
}

function NotificationItem(params: { notificationState: NotificationState; stateItem: IStateNotification }): JSX.Element {
    const item = NotificationMenu.toMenuNotification(params.stateItem);

    let icon = item.icon;
    if (!icon) {
        icon = NotificationMenu.IconWarning;
    }

    let content = item.content as any;
    if (typeof content === 'object') {
        content = JSON.stringify(content, null, 4);
    }
    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        params.notificationState.markAsRead(parseInt(e.currentTarget.dataset.id as string, 10));
    };
    return (
        <ErrorDisplayComponent>
            <li key={item.id} className={Class({ important: item.important, unread: item.unread })}>
                <FontAwesomeIcon className={Class('icon-container', icon.className)} icon={icon.icon} transform={{ size: 8 }} />
                <div className="content-container">
                    <h6>{item.title}</h6>
                    <span className="date">{relativeTime(item.created_at)} ago</span>
                    <p dangerouslySetInnerHTML={{ __html: content }} />
                    {item.unread && (
                        <span className="mark-as-read" data-id={item.id} onClick={onClick}>
                            mark as read
                        </span>
                    )}
                </div>
            </li>
        </ErrorDisplayComponent>
    );
}

@observer
export default class NotificationMenu extends React.Component<INotificationMenuProps, INotificationMenuState> {
    public static IconError = { colour: '#F44336', name: 'fa-times' };
    public static IconWarning = { icon: { iconName: 'exclamation', prefix: 'fas' }, style: { color: '#FF9800' } };
    public static IconCheck = { colour: '#4CAF50', name: 'fa-check' };
    public static IconInfo = { colour: '#2196F3', name: 'fa-info' };
    public static IconQuestion = { colour: '#9E9E9E', name: 'fa-question' };
    public static toMenuNotification = createTransformer(toMenuNotification);
    private menuRef: Menu;

    private renderEmpty(): JSX.Element {
        return (
            <div className="empty">
                <span>No Notifications</span>
            </div>
        );
    }

    private renderList(): JSX.Element[] {
        return this.props.notifications.notifications.map(item => <NotificationItem notificationState={this.props.notifications} stateItem={item} key={item.id} />);
    }

    @bind
    private onMarkAllAsRead(e: React.MouseEvent<HTMLElement>): void {
        e.preventDefault();
        e.stopPropagation();
        this.props.notifications.markAllAsRead();
    }

    public render(): JSX.Element {
        const notificationState = this.props.notifications;
        const isEmpty = notificationState.notifications.length === 0;
        const unreadCount = notificationState.unreadCount;
        return (
            <span className="notification-menu-container">
                <Layer className={this.props.className}>
                    <FontAwesomeIcon id="notification-menu-icon" icon="bell" />
                    {unreadCount > 0 && <Layer.Counter className="counter">{unreadCount}</Layer.Counter>}
                </Layer>
                <Menu ref={(ref: any) => (this.menuRef = ref)} className="notification-menu" target="#notification-menu-icon" mount="left-top" noPadding preventClose>
                    <div className="notification-area-container">
                        <div className="header">
                            <h5>Notifications</h5>
                            <span onClick={this.onMarkAllAsRead}>Mark all as read</span>
                        </div>
                        <ul>{isEmpty ? this.renderEmpty() : this.renderList()}</ul>
                        <a className="view-all-link" href="/notifications" target="_blank">
                            View All
                        </a>
                    </div>
                </Menu>
            </span>
        );
    }
}
// Just a quick global to get access to Icons
(global as any).NotificationMenu = NotificationMenu;
