const objectOperators = [
    {
        value: "eq",
        label: "Equals",
        icon: "=",
    },
    {
        value: "neq",
        label: "Not Equals",
        icon: "≠",
    },
    {
        value: "isnull",
        label: "Is Null",
        icon: "",
    },
    {
        value: "isnotnull",
        label: "Is Not Null",
        icon: "",
    },
];

export default objectOperators;
