// var kendoBindingGearsObject, out$ = typeof exports != 'undefined' && exports || this;
import {parse, stringify} from "json5";
import {notification} from "../helpers/Notification";

export function kendoBindingGearsObject() {
    const Widget = kendo.ui.Widget;
    const kendoDataBinders: any = kendo.data.binders;
    kendoDataBinders.gearsObject == null && (kendoDataBinders.gearsObject = kendo.data.Binder.extend({
        init(element: any, bindings: any, options: any){
            let e, this$ = this;
            kendo.data.Binder.fn.init.call(this, element, bindings, options);
            try {
                this.element.bind("change", function(){
                    this$.change();
                });
            } catch (e$) {
                e = e$;
                $(this.element).on("change", function(){
                    this$.change();
                });
            }
        },
        refresh(){
            let ref$, value, e;
            const expanded = (ref$ = this.options) != null && ref$.expanded ? "  " : undefined;
            value = this.bindings.gearsObject.get();
            if ($.isEmptyObject(value)) {
                value = "";
            }
            if (typeof value !== "string") {
                value = stringify(value, undefined, expanded);
            }
            try {
                return this.element.value(value);
            } catch (e$) {
                e = e$;
                return $(this.element).val(value);
            }
        },
        change(){
            let value, e;
            console.log(this.element);
            try {
                value = this.element.value();
            } catch (e$) {
                e = e$;
                value = $(this.element).val();
            } try {
                value = parse(value);
            } catch (e$) {
                e = e$;
                $(this.element).addClass("gears-invalid");
                window.value = value;
                notification({
                    title: "Invalid Input",
                    content: "<i class='fa fa-warn' id='alertboxsmall'></i> <i>Could not parse input</i>",
                    messageType: "warning",
                    displayType: "bigBox",
                    timeout: 4000,
                });
                throw e;
            }
            $(this.element).removeClass("gears-invalid");
            this.bindings.gearsObject.set(null);
            console.log("Setting value: ", value);
            this.bindings.gearsObject.set(value);
            this.refresh();
        },
    }));
    (kendo.data.binders.widget).gearsObject == null && (kendo.data.binders.widget.gearsObject = kendo.data.binders.gearsObject);
}
