import * as Promise from 'bluebird';
import { camelize, underscore } from 'inflection';
import { Once } from 'lodash-decorators';
import { extendObservable } from 'mobx';
import { IReactComponent, observer } from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AnyLazySource } from '../react-components/WebfrontRegistry';
import cable from './actioncable';
import { previewEmail } from './Email/previewEmail';
import { ConfigurationError, UserCancelled, ValidationError } from './Errors';
import GearsDataSource from './GearsDataSource';
import { Model } from './GearsModel/Model';
import { addGridEditPopovers } from './grid-edit-popovers';
import { addGridReporter } from './grid-reporter';
import GridActionSubscriber from './grid/GridActionSubscriber';
import { addGearsFilter, default as GridFilter } from './grid/GridFilter';
import addGridTabs from './grid/GridTabs';
import GridConfig from './GridConfig';
import addGridKeyboard from './GridKeyboard';
import { addMultiSelect, MultiSelect } from './GridMultiSelect';
import { getConfirmation, setGearsFavicon } from './helpers';
import { handleError } from './helpers/HandleError';
import { dialog } from './helpers/Notification';
import { resizeGrid } from './helpers/Resizing';
// import kendo from '../../vendor/assets/kendoui/js';

declare const Gears: any;
declare const gearsState: any;
declare const _: _.LoDashStatic;

let observableObject;
let observableObjectInit;
if (typeof kendo === 'undefined') {
    observableObject = {
        extend() {
            return this;
        },
    };
} else {
    observableObject = kendo.data.ObservableObject;
    observableObjectInit = observableObject.fn.init;
}

export const all_grids: GridController[] = [];
Gears.all_grids = all_grids;

export function resizeAllGrids() {
    return _.each(all_grids, it => it.resizeGrid());
}

export function friendlyRefreshAllGrids() {
    return Promise.all(Promise.map(all_grids, (it: any) => it.dataSource.friendlyRefresh()));
}

interface User {
    user_group_id: number;
}

interface IKendoWorkarounds {
    user(): User | null;

    userGroupId(): number;

    userSuperUser(): boolean;

    userIsAdmin(): boolean;

    refreshGridHeaders(): void;

    setupGridHeader(): void;
}

interface IJQueryWorkarounds {
    ensureAllTabsVisible(): void;

    ensureDetailTabVisible(): void;

    resizeGrid(): void;

    resize(): void;

    _detailAnimate($row: JQuery): void;

    inlineEditClick(event: JQuery.Event<HTMLElement, null>): void;

    testFullscreen(): void;
}

interface IEditModalFeatures {
    formValidator?: any;

    hideModal(): Promise<void>;

    addRecordButton(): void;

    addRecord(): void;

    modalIsOpen(): boolean;

    recordUnchanged(): boolean;
}

interface GridControllerHooks {
    afterSave?(record: kendo.data.Model): void;
}

interface IPopoverEditModeFeatures {
    popoverEditMode: boolean;
}

export class GridController extends kendo.data.ObservableObject implements IKendoWorkarounds, IJQueryWorkarounds, GridControllerHooks, IEditModalFeatures {
    config: GridConfig;
    gearsGrid: kendo.ui.Grid;
    dataSource: kendo.data.DataSource;
    gearsDataSource: GearsDataSource;
    selectedRecord: Model | null = null;
    afterSave?: (record: kendo.data.Model) => void;
    parentGrid: GridController;
    customInitialize?: () => any;
    saveActive: boolean = false;
    addRecord: () => void;
    multiSelect?: MultiSelect;
    popoverEditMode: boolean = false;
    formValidator?: any = null;
    gearsFilter: GridFilter;
    setupTab: () => void;
    private fullscreen: boolean;
    private modalEditMode: boolean = true;
    private single_record_loading: boolean = false;
    private dataLoading: boolean = false;
    private modalView: kendo.View;
    private children: { [name: string]: GridController };
    private childTotals: { [name: string]: any } = {};
    private lookupSubscription?: ActionCable.Channel;
    private gridActionSubscriber: GridActionSubscriber;
    private startingRecordId: string | number | null;

    constructor(config: GridConfig) {
        super();
        if (!config) {
            throw new ConfigurationError('No Config specified for grid');
        }

        Gears.initialize!;
        super.init(this);
        Gears.all_grids.push(this);

        this.config = config;
        this.config.gearsGrid = this;
        this.children = {};
        this.dataSource = Gears.kendoDataSource(config);
        this.dataSource.bind('requestStart', (e: Event) => {
            if (!this.permitDataLoading) {
                e.preventDefault();
            }
        });
        if (this.config.has_modal) {
            this.addRecord = () => this.addRecordModal();
        } else {
            this.addRecord = () => {
                const item = new this.config.gearsModel({});
                return this.set('selectedRecord', item);
            };
        }
    }

    get isEditing(): boolean {
        return $(this.config.modalDomId()).is(':visible');
    }

    get canUpdateGrid(): boolean {
        const pGrid = this.parentGrid;
        return !this.isEditing && !(pGrid && !pGrid.get('selectRecord.id'));
    }

    get permitDataLoading(): boolean {
        return !!this.parentGrid || (this.gearsGrid && this.gearsGrid.element && $(this.gearsGrid.element).is(':visible'));
    }

    destroy(): void {
        const { lookupSubscription, gridActionSubscriber } = this;
        if (lookupSubscription) {
            lookupSubscription.unsubscribe();
        }
        if (gridActionSubscriber) {
            gridActionSubscriber.destroy();
        }
    }

    @Once()
    setupModalForNestedGrids(): void {
        const modalDomId = this.config.modalDomId();
        $(modalDomId).on('shown.bs.modal', () => {
            this.resizeChildren();
            return this.refreshChildren();
        });
    }

    selectRecord(): kendo.data.Model | null {
        const $row: any = this.gearsGrid.select();
        let rowId: number | string = $row.data('id');
        let rowData: any;
        let idHasChanged: boolean;
        if (rowId) {
            rowData = this.dataSource.get(rowId);
            idHasChanged = (rowData && rowData.id) !== (this.selectedRecord && this.selectedRecord.id);
        } else {
            rowId = $row.data('uid');
            rowData = _.find(this.dataSource.data(), (it: any) => it.uid === rowId);
            idHasChanged = true;
        }

        this.set('selectedRecord', rowData);
        if (!rowData) {
            console.log('Record not found');
        }
        if (!rowData || !idHasChanged) {
            return null;
        }
        this.ensureAllTabsVisible();
        this._detailAnimate($row);
        return this.selectedRecord;
    }

    addRecordButton(): void {
        this.addRecord();
    }

    addRecordModal(): void {
        const item = new this.config.gearsModel({});
        this.set('selectedRecord', item);
        this.editRecord();
    }

    editRecord = () => {
        this.disableFullscreenForModal();
        if (this.get('selectedRecord') && this.config.has_modal) {
            $(this.config.modalDomId()).modal({
                backdrop: 'static',
                keyboard: false,
            });
        }
        this.refreshModalButton(0, true);
    };

    editRecordButton(): void {
        this.editRecord();
    }

    editRecordModal(): void {
        this.editRecord();
    }

    hasSelectedRecord(): boolean {
        return this.get('selectedRecord.id') !== undefined;
    }

    reselectSelected(): any {
        const id = this.get('selectedRecord.id');
        if (id) {
            return this.selectRecordId(id);
        }
    }

    selectRecordId(id: number | string): boolean {
        const $idFind = $(`tr[data-id="${id}"]`);
        const found = !this.gearsGrid.select($idFind);
        if (!found) {
            this.set('selectedRecord', null);
        }
        // console.log("Selecting Record Id 333", id, $idFind, found, $idFind[0], $idFind[0].scrollIntoViewIfNeeded);
        const elem: any = $idFind[0];
        if (elem) {
            elem.scrollIntoViewIfNeeded ? elem.scrollIntoViewIfNeeded() : 'Do nothing' || elem.scrollIntoView();
        }
        return found;
    }

    setupGrid(): void {
        const gridOptions = {
            change: () => this.selectRecord(),
            dataBound: () => {
                let ref$, ref1$;
                this.showHideColumns();
                if (this.config.collapse_groups) {
                    if (this.gearsDataSource) {
                        this.gearsDataSource.collapseGroups();
                    }
                }
                if (this.gearsDataSource) {
                    this.gearsDataSource.markIncompleteLoad();
                }
                this.resizeGrid();
                return _.defer(() => {
                    if (this.startingRecordId != null) {
                        this.selectRecordId(this.startingRecordId);
                        if (this.single_record_loading && this.selectedRecord) {
                            this.editRecord();
                            this.single_record_loading = false;
                            return (this.startingRecordId = null);
                        } else {
                            return (this.startingRecordId = null);
                        }
                    } else {
                        return this.reselectSelected();
                    }
                });
            },
            dataSource: this.dataSource,
            edit: () => $('.k-grid-update.k-primary').removeClass('k-primary'),
            editable: {
                mode: 'inline',
            },
            filterable: true,
            navigatable: true,
            pageable: false,
            scrollable: true,
            selectable: true,
            sortable: true,
        };
        if (this.config.gridConfig) {
            _.merge(gridOptions, this.config.gridConfig());
        }

        const domGrid = this.tableDomElement();

        this.gearsGrid = domGrid.kendoGrid(gridOptions).data('kendoGrid');
        if (!this.gearsGrid) {
            return;
        }

        domGrid.addClass('instantiated');
        $(this.config.tableDomId() + ' .k-grid-content').on('dblclick', 'tr:not(.k-grouping-row)', e => {
            _.defer(() => {
                if (!$(e.currentTarget).hasClass('k-state-selected')) {
                    $(e.currentTarget).click();
                }
                this.editRecordButton();
            });
        });
        domGrid.on('click', '[data-web-function]', e => _.defer(() => this.webFunctionButton(e)));
        domGrid.on('click', '.inline-edit', e => _.defer(() => this.inlineEditClick(e)));
        $(this.config.tableDomId() + ' .k-grid-content').on('click', e => {
            if ($(e.target).is('.k-grid-content')) {
                this.selectedRecord = null;
                return this.gearsGrid.clearSelection();
            }
        });
        this.setDataLoadingIndicator();
        this.gearsGrid.bind('dataBound', this.ensureGridFormatting);
        this.ensureGridHeaderFormatting();
        if (this.config.refresh_seconds != null) {
            return setInterval(() => {
                let ref$;
                if ($(this.config.modalDomId()).is(':visible') || !((ref$ = this.parentGrid) != null && ref$.get('selectRecord.id'))) {
                    return console.log('Modal busy, not refreshing.');
                } else {
                    this.gearsGrid.element.addClass('hide-kendo-loading-mask');
                    (this.dataSource as any).friendlyRefresh();
                    return _.delay(() => this.gearsGrid.element.removeClass('hide-kendo-loading-mask'), 3000);
                }
            }, this.config.refresh_seconds * 1000);
        }
    }

    showChildGrid = (grid_name: string) => {
        const record = this.get('selectedRecord');
        const customFuncName: string = 'showChildGrid' + camelize(grid_name);
        const customFunc = (this as any)[customFuncName];
        if (!customFunc) {
            return true;
        }
        try {
            return customFunc.call(this, record);
        } catch (e) {
            return false;
        }
    };

    csv_export(filename?: string): void {
        const params: any = {};

        if (filename) {
            params.filename = filename;
        }

        const filter = this.dataSource.filter() || {};
        if (filter && filter.filters && filter.filters.length > 0) {
            params.filter = filter;
        }
        window.open(this.config.webFunctionUrl(null, 'export.csv', 'table') + '?' + $.param(params));
    }

    user(): User | null {
        this.get('selectedRecord');
        return gearsState.user;
    }

    userGroupId(): number {
        const user: User | null = this.user();
        return user ? user.user_group_id : NaN;
    }

    /**
     * @deprecated
     * @returns {boolean}
     */
    userSuperUser(): boolean {
        return this.userGroupId() === 100;
    }

    /**
     * @deprecated
     * @returns {boolean} The user is Admin (including super users)
     */
    userIsAdmin(): boolean {
        return [100, 1].includes(this.userGroupId());
    }

    ensureAllTabsVisible(): void {
        Gears.ensureAllTabsVisible();
    }

    ensureDetailTabVisible(): void {
        const $container: JQuery = $('#' + this.config.detailContainerDomId());
        const $selected: JQuery = $container.find('ul.nav-tabs > .active > a');
        if (!$selected.is(':visible')) {
            $container
                .find('ul.nav-tabs > li > a:visible')
                .first()
                .tab('show');
        }
    }

    showDetails(): boolean {
        return this.get('selectedRecord') != null;
    }

    tableDomElement(): JQuery<Element> {
        return this.findUniqueElement(this.config.tableDomId(), 'Grid DOM Id');
    }

    findUniqueElement(elString: string, name: string): JQuery<Element> {
        let domGrid = $(elString);

        // this.config.modelName().match(/Sql/) && console.log("Setting up grid " + this.config.modelName(), domGrid, this);
        if (domGrid.length === 0) {
            console.log(`${name} not found for: ${this.config.modelName()}`);
        } else if (domGrid.length > 1) {
            // console.log("Get more specific!", `.${this.parentGrid.config.uModelName()} ${this.config.tableDomId()}`);
            if (this.parentGrid) {
                domGrid = $(`.${this.parentGrid.config.uModelName()} ${elString}`);
            } else {
                const sortedDomItems = $(domGrid.sort((i: Element) => $(i).parents().length));
                domGrid = $(sortedDomItems[0]);
            }
            if (domGrid.length === 0) {
                console.log(`Specific ${name} not found for: ${this.config.modelName()}`);
            }
        }
        return domGrid;
    }

    headerDomElement(): JQuery<Element> {
        return this.findUniqueElement(this.config.headerContainer(), 'Grid Header Container');
    }

    resizeGrid(): void {
        if (this.config.has_gears_grid_container) {
            const $table: JQuery = this.tableDomElement();
            const tableIsFlex = $table.parent().css('display') === 'flex';
            resizeGrid($table);
        } else {
            console.log('Error! has_gears_grid_container is false for', this);
        }
    }

    testFullscreen(): void {
        const fullscreen = $(this.gearsGrid.element).closest('#jarviswidget-fullscreen-mode').length === 1;
        this.set('fullscreen', fullscreen);
    }

    refreshGridHeaders(): void {
        this.gearsGrid.setOptions(this.gearsGrid.options);
        this.gearsGrid.refresh();
    }

    _detailAnimate(row: JQuery): void {
        if (!$.fn.velocity) {
            return;
        }
        const $container: JQuery = $('#' + this.config.detailContainerDomId());
        const $content: JQuery = $container.find('> div, > section ');
        if ($content.attr('data-animate') === 'false' || $content.hasClass('velocity-animating')) {
            return;
        }

        $content.velocity({ scaleX: 0.95, scaleY: 0.95 }, { duration: 200 }).velocity('reverse');
        _.delay(() => {
            $container.attr('style', '');
            $content.attr('style', '');
        }, 500);
    }

    setupGridHeader(): void {
        const $container = this.headerDomElement();

        const headerTemplate = this.config.headerTemplate;
        // if (!($header_template.length && $container.length)) {
        //     return;
        // }
        // console.log("setupGridHeader", header_template, this.config);
        const headerView = new kendo.View(headerTemplate as any, {
            evalTemplate: true,
            model: this,
            wrap: false,
        });
        headerView.render($container);
    }

    showHideGrouping(): void {}

    showHideColumns(): void {
        this.testFullscreen();
        this.showHideGrouping();
        let anyChange = false;
        _(this.gearsGrid.columns).each((column: any) => {
            const desired = column.visible == null || typeof column.visible === 'function' ? !!column.visible.call(this) : !!column.visible;
            const current = !column.hidden;
            if (desired !== current) {
                anyChange = true;
                if (desired) {
                    return this.gearsGrid.showColumn(column.field);
                } else {
                    return this.gearsGrid.hideColumn(column.field);
                }
            }
        });
        if (anyChange) {
            this.resizeColumns();
            return this.gearsGrid.refresh();
        }
    }

    spinCog(x: any) {
        return x.toggleClass('fa-spin');
    }

    ensureGridFormatting() {
        $('.tooltip').remove();
        ($('[data-toggle=tooltip]') as any).tooltip({ html: true });
    }

    ensureGridHeaderFormatting() {
        let containerId =
            '#' +
            $(this.gearsGrid.element)
                .closest('.widget-grid')
                .attr('id');
        if (!$(containerId).length) {
            containerId =
                '#' +
                $(this.gearsGrid.element)
                    .closest('.tooltip-container')
                    .attr('id');
        }
        $(this.gearsGrid.element)
            .find('th span[data-toggle=popover], th span[data-rel=popover]')
            .each((i, tcelltip) => {
                const el: any = $(tcelltip);
                const parent = el.parent();
                const th = parent.closest('th');
                th.addClass(el.attr('class'));
                _.each(
                    [
                        'data-rel',
                        'data-title',
                        'data-original-title',
                        'data-width',
                        'data-content',
                        'data-delay-show',
                        'data-delay-hide',
                        'data-trigger',
                        'data-placement',
                        'data-html',
                    ],
                    battr => {
                        th.attr(battr, el.attr(battr));
                        el.removeAttr(battr);
                    },
                );
                parent.html(el.html());
                th.attr('data-container', 'body');
                ($(this.gearsGrid.element).find('thead [data-rel=popover]') as any).webuiPopover();
            });
    }

    fullscreenColumns() {
        this.resizeGrid();
        return this.showHideColumns();
    }

    setGrouping(it: any) {
        return this.gearsDataSource.setGrouping(it);
    }

    // public importConfig() {
    //     const convertProperties = (result: any, val: any, key: string) => {
    //         if (typeof val === "object" && (val.set != null || val.get != null || val.value != null)) {
    //             result[key] = computed(val.get, { setter: val.set });
    //         } else if (_.isFunction(val)) {
    //             result[key] = observable.box(val);
    //         } else {
    //             result[key] = val;
    //         }
    //         return result;
    //     };
    //     const props = _(this.config.view_model).reduce(convertProperties, {});
    //     // console.log("Extending with props", props);
    //     return extendObservable(this, props);
    // }

    resizeColumns(): void {
        const fields = _.filter(this.config.grid.columns, (i: any) => i.fullscreen_width);
        if (this.get('fullscreen')) {
            _.each(fields, (field: any) => this.resizeColumn(field.field, field.fullscreen_width));
        } else {
            _.each(fields, (field: any) => this.resizeColumn(field.field, field.width));
        }
    }

    resizeColumn(field: string, width: number) {
        const colId = _(this.gearsGrid.columns)
            .filter(col => !col.hidden)
            .findIndex({ field });
        this.gearsGrid.element.find('.k-grid-header-wrap, .k-grid-content, .k-grid-footer').each((i, h) => {
            $(h)
                .find('colgroup col')
                .eq(colId)
                .css({ width });
        });
    }

    importConfig(): void {
        const decorators: { [key: string]: any } = {};
        const values: { [key: string]: any } = {};
        const addProperties = (val: any, key: string) => {
            if (typeof val === 'object' && (val.set != null || val.get != null || val.value != null)) {
                // decorators[key] = computed;
                Object.defineProperty(this, key, val);
            } else if (_.isFunction(val)) {
                // result[key] = observable.ref(val);
                this[key] = val;
                // values[key] = val;
                // decorators[key] = observable.ref;
            } else {
                values[key] = val;
            }
        };
        _(this.config.view_model).each(addProperties);
        // console.log("Extending Observable", this, values, decorators);
        extendObservable(this, values, decorators);
    }

    saveModalButton(e: Event): Promise<any> {
        let options: any;
        if (e != null && e.target) {
            const $button = $(e.target).closest('a,button');
            options = {
                closeOnSuccess: $button.attr('data-close-on-success'),
                forceSave: $button.attr('data-force-save'),
            };
            if (options.forceSave != null) {
                options.forceSave = JSON.parse(options.forceSave);
            }
            if (options.closeOnSuccess != null) {
                options.closeOnSuccess = JSON.parse(options.closeOnSuccess);
            }
        }
        return this.saveModal(options).catch(handleError);
    }

    saveModal(saveOptions?: any): Promise<any> {
        saveOptions == null && (saveOptions = {});
        if (!this.modalIsOpen() && !this.popoverEditMode && !this.config.showPageId) {
            return Promise.reject('No edit feature is open!');
        }
        if (!this.selectedRecord) {
            return Promise.reject('No record is selected');
        }
        saveOptions.forceSave == null && (saveOptions.forceSave = false);
        saveOptions.closeOnSuccess == null && (saveOptions.closeOnSuccess = true);
        if (!this.modalEditMode) {
            if (saveOptions.closeOnSuccess) {
                this.hideModal();
            }
            return Promise.resolve();
        }
        if (!saveOptions.forceSave) {
            if (this.formValidator != null) {
                const v = this.formValidator.validate();
                if (!v) {
                    return Promise.reject(new ValidationError('Invalid Form Data'));
                }
            }
        }
        return this.saveRecord(this.selectedRecord, saveOptions).then(() => {
            console.log('Save success!');
            if (saveOptions.closeOnSuccess) {
                return this.hideModal();
            }
        });
    }

    saveModalNoClose(saveOptions: any): Promise<any> {
        saveOptions == null && (saveOptions = {});
        saveOptions.closeOnSuccess = false;
        return this.saveModal(saveOptions);
    }

    hideModal(): Promise<any> {
        $(this.config.modalDomId()).modal('hide');
        if (this.get('fullscreen')) {
            $('.' + this.config.uModelName() + '-widget .jarviswidget-fullscreen-btn').trigger('click');
        }
        return Promise.resolve();
    }

    modalIsOpen(): boolean {
        return $(this.config.modalDomId() + '.in').length === 1;
    }

    recordUnchanged(): boolean {
        const record = this.get('selectedRecord');
        return !record || !record.dirty || _.values(this.gearsDataSource.recordChanges(record)).length === 0;
    }

    cancelModal(confirmed?: boolean): Promise<any> {
        let error;
        confirmed == null && (confirmed = false);
        if (!this.config.showPageId && !this.modalIsOpen()) {
            return Promise.reject('Modal is not Open');
        }
        if (confirmed === true || this.recordUnchanged()) {
            try {
                this.dataSource.cancelChanges();
            } catch (e$) {
                error = e$;
                (this.dataSource as any)._data = _.compact((this.dataSource as any)._data);
            }
            this.refreshModalButton(0, true);
            $(this.config.modalDomId()).modal('hide');
            if (this.get('fullscreen')) {
                $('.' + this.config.uModelName() + '-widget .jarviswidget-fullscreen-btn').trigger('click');
            }
            return Promise.resolve(true);
        } else {
            return dialog({
                content: 'Are you sure you wish to abandon any changes?',
                title: 'Close Edit Form',
            }).then(() => this.cancelModal(true));
        }
    }

    enableClassWebFunction(options: any): string {
        if (this.showWebFunction(options)) {
            return '';
        } else {
            return 'disabled';
        }
    }

    showWebFunction(options: any) {
        let name, type, record, custom_func;
        if (typeof options === 'string') {
            options = JSON.parse(decodeURIComponent(options));
        }
        if (options) {
            name = options.name;
            type = options.type;
        }
        name == null && (name = 'unamed_function');
        type == null && (type = 'record');
        record = this.get('selectedRecord');
        custom_func = 'show' + camelize(name);
        if (record && record[custom_func]) {
            return record[custom_func](record);
        }
        if (this && (this as any)[custom_func]) {
            return (this as any)[custom_func](record);
        }
        if (type === 'record') {
            return !!record;
        }
        if (type === 'multiple_records') {
            return typeof this.hasMultiSelection === 'function' ? this.hasMultiSelection() : this.hasMultiSelection;
        }
        return true;
    }

    hasMultiSelection() {
        return this.multiSelect && this.multiSelect.hasMultiSelection;
    }

    enableWebFunction(options: any) {
        return this.showWebFunction(options) !== 'disable';
    }

    showField(field_name: string) {
        let ref$;
        return (ref$ = this.get('selectedRecord')) != null ? ref$.showField(field_name) : void 8;
    }

    enableField(field_name: string) {
        let ref$;
        return (ref$ = this.get('selectedRecord')) != null ? ref$.enableField(field_name) : void 8;
    }

    createRecordModal(): Promise<any> {
        const item = new this.config.gearsModel({});
        return this.saveRecord(item).then(() => this.editRecord());
    }

    /**
     * @deprecated
     */
    copyRecord() {
        if (!this.selectedRecord) {
            throw new ValidationError('Cannot copy record that is not selected');
        }
        const own$ = {}.hasOwnProperty;
        const old_record: any = this.selectedRecord.toJSON();
        delete old_record.id;
        this.addRecord();
        const new_record = this.selectedRecord;
        this.selectedRecord = null;
        for (const key in old_record) {
            if (own$.call(old_record, key)) {
                const value = old_record[key];
                new_record.set(key, value);
            }
        }
        this.set('selectedRecord', new_record);
    }

    refreshRecordParents(): Promise<any> {
        let ref$;
        return Promise.join(this.gearsDataSource.loadFreshRecord(), (ref$ = this.parentGrid) != null ? ref$.refreshRecordParents() : void 8);
    }

    refreshButton(): Promise<any> {
        return this.refreshWithLookups();
    }

    refreshWithLookups(): Promise<any> {
        this.set('dataLoading', true);
        this.refreshChildren();
        return Promise.join(this.dataSource.read(), this.readLookups()).finally(() => Promise.delay(10).then(() => this.set('dataLoading', false)));
    }

    disableFullscreenForModal(): void {
        this.testFullscreen();
        if (this.get('fullscreen')) {
            this.set('fullscreenMode', true);
            $('.' + this.config.uModelName() + '-widget .jarviswidget-fullscreen-btn').trigger('click');
        }
    }

    deleteRecordButton(): Promise<any> {
        return Gears.notification({
            buttons: [
                {
                    className: '',
                    name: 'Cancel',
                    reject: true,
                    value: 'cancel',
                },
                'Delete!',
            ],
            content: 'Are you sure you want to mark record as deleted?',
            icon: 'warning',
            title: 'Delete ' + this.config.humanName(),
        }).then(() => this.deleteRecord());
    }

    deleteRecord(): Promise<any> {
        if (!this.selectedRecord) {
            throw new ValidationError('Attempting to delete record without record selected.');
        }
        let del: Promise<any>;
        if (this.selectedRecord.deleteRecord != null) {
            del = Promise.resolve(this.selectedRecord.deleteRecord(this));
        } else {
            del = this.gearsDataSource.deleteById(this.selectedRecord.id);
        }
        return del.then(() => this.dataSource.fetch()).catch(handleError);
    }

    refreshButtonIcon(): string {
        if (this.gridActionSubscriber && this.gridActionSubscriber.hasChanges) {
            return `<i class="jarvisicon fa fa-refresh txt-color-red"></i>`;
        } else {
            return `<i class="jarvisicon fa fa-refresh"></i>`;
        }
    }

    //     $(this.config.tableDomId() + " .k-grid-content").on('dblclick', "tr:not(.k-grouping-row)", (e) => {
    //     _.defer(() => {
    //     if(!$(e.currentTarget).hasClass('k-state-selected')) {
    //     $(e.currentTarget).click();
    // }
    // this.editRecordButton();
    // });
    // });

    /**
     * @deprecated
     * @param hack
     * @returns {string}
     */
    testLink(hack: string): string {
        let hack_array, table, id_field;
        hack_array = hack.split('|');
        table = hack_array[0];
        id_field = hack_array[1];
        return table + '#edit' + this.get('selectedRecord.' + id_field);
    }

    parseConfirmation(confirmationOptions: any): { required: string; title: string; message: string } | undefined {
        if (!confirmationOptions) {
            return;
        }
        let { required, title, message } = JSON.parse(decodeURIComponent(confirmationOptions));
        if (required == null) {
            required = !!(title || message);
        }
        return {
            message,
            required,
            title,
        };
    }

    /**
     * @deprecated
     * @param e
     */
    webMethodButton(e: JQuery.Event<HTMLElement, null>) {
        this.webFunctionButton(e);
    }

    webFunctionButton(e: JQuery.Event<HTMLElement, null>) {
        const $button = $(e.target).closest('a,button');
        const $row = $button.closest('tr');
        if (!$row.hasClass('k-state-selected')) {
            $row.click();
        }
        const running = $button.hasClass('web-function-running');
        if (running) {
            return;
        }
        const data = $button.data();
        if (data.enabled === false) {
            return;
        }
        const name: string = data.webFunction;
        const text: string = $button.text();
        const type: string = data.webFunctionType;
        const url: string = data.webFunctionUrl;
        let icon: string | null = data.icon;
        if (!icon) {
            const svgClass = $button.find('svg').prop('className');
            icon = svgClass && 'fa ' + svgClass.baseVal;
        }

        let confirmation: any = this.parseConfirmation(data.webFunctionConfirmation);
        if (!confirmation) {
            const conf = (this as any)[type + 'Confirmation'];
            if (conf) {
                confirmation = conf(text);
            }
        }
        if (this.modalIsOpen() && !this.recordUnchanged()) {
            confirmation == null && (confirmation = {});
            confirmation.required = true;
            confirmation.content || (confirmation.content = confirmation.message || confirmation.text || 'Save record and run function?');
            confirmation.buttons ||
                (confirmation.buttons = [
                    {
                        name: 'Cancel',
                        reject: true,
                        value: 'cancel',
                    },
                    {
                        name: 'OK',
                        onClick: () => this.saveRecord(),
                        value: 'ok',
                    },
                ]);
        }
        // console.log("Confirmation", confirmation, this.modalIsOpen(), this.recordUnchanged());
        const afterHook: (data: any) => any = (this as any)['after' + camelize(name)];
        const dataToSend = _.merge({ icon }, data.dataToSend, this.webFunctionData());
        let javascriptOverride: Function | null = null;
        if (this.selectedRecord && this.selectedRecord[name]) {
            javascriptOverride = _.bind(this.selectedRecord[name], this.selectedRecord);
        } else if ((this as any)[name] != null) {
            javascriptOverride = _.bind((this as any)[name], this);
        }
        return this.callWebFunctionWithConfirmation(
            name,
            {
                afterHook,
                confirmation,
                data,
                dataToSend,
                javascriptOverride,
                label: text,
                type,
                url,
            },
            $button,
        );
    }

    // public previewServerEmail(method: string, arg$: {
    //     message?: string,
    //     label?: string,
    //     dataToSend?: object,
    //     notification?: boolean,
    //     url?: string,
    //     type?: "record" | "multiple_records" | "table",
    //     confirmation?: object,
    // } = {notification: false}): Promise<any> {
    //     return previewEmail(this
    //         .callWebFunction(method, arg$)
    //         .tap((d) => console.log("CWF returned", d))
    //         .get('data')
    //     );
    // }

    webFunctionData() {
        const { parentGrid } = this;
        const parentRecord = parentGrid && parentGrid.selectedRecord;
        const filter = this.dataSource.filter() || {};
        return {
            filter: filter && filter.filters && filter.filters.length > 0 ? filter : undefined,
            parent: parentRecord ? parentRecord.toJSON() : undefined,
            parent_type: parentGrid ? parentGrid.config.gridName() : undefined,
            record: this.selectedRecord && this.selectedRecord.toJSON(),
        };
    }

    callWebFunctionWithConfirmation(method: string, options: any, button?: any) {
        const getConf = (options.confirmation && options.confirmation.required === false) || getConfirmation(options.confirmation);
        return Promise.resolve(getConf)
            .then(() => {
                if (button != null) {
                    button.addClass('web-function-running');
                }
                if (options.javascriptOverride != null) {
                    return options.javascriptOverride(options.data);
                } else {
                    return this.callWebFunction(method, options);
                }
            })
            .then(result => {
                if (options.afterHook) {
                    return options.afterHook.call(this, result);
                }
            })
            .catch(UserCancelled, () => console.log('User cancelled'))
            .finally(() => (button != null ? button.removeClass('web-function-running') : void 8));
    }

    callWebFunction(
        method: string,
        arg$: {
            message?: string;
            label?: string;
            dataToSend?: object;
            url?: string;
            type?: 'record' | 'multiple_records' | 'table';
            notification?: boolean;
            confirmation?: object;
        },
    ): Promise<any> {
        if (arg$ == null) {
            arg$ = {};
        }
        let { message, label, dataToSend, url, type, confirmation, notification } = arg$;
        label == null && (label = message);
        console.log('Call web method', method, type, dataToSend);
        if (type === 'multiple_records') {
            dataToSend = {
                data: dataToSend,
                ids: this.multiSelect && this.multiSelect.multiSelectionIds,
            };
        } else {
            dataToSend == null && (dataToSend = {});
        }
        url == null && (url = this.config.webFunctionUrl(this.get('selectedRecord.id'), method, type));
        return Promise.resolve(
            $.ajax({
                data: dataToSend,
                dataType: 'json',
                type: 'POST',
                url,
            }),
        )
            .then(data => {
                let messageBox, ref$;
                console.log('Web function Result: ', data);
                if (data.error) {
                    throw data;
                }
                if (notification === false) {
                    return data;
                }
                messageBox = {
                    animation: data.animation,
                    content: message != null ? message : (ref$ = data.message) != null ? ref$ : (ref$ = data.content) != null ? ref$ : label,
                    contentFormat: data.contentFormat,
                    dialogStyle: data.dialogStyle,
                    displayType: data.displayType || 'bigBox',
                    grid: data.grid,
                    icon: data.icon,
                    messageType: data.messageType || 'success',
                    timeout: data.timeout === false ? undefined : (ref$ = data.timeout) != null ? ref$ : 10000,
                    title: data.title || 'Action Complete',
                    width: data.width,
                };
                Gears.notification(messageBox);
            })
            .tap(() => {
                if (this.parentGrid) {
                    this.parentGrid.refresh();
                } else {
                    this.refresh();
                }
            })
            .catch(errorData => {
                if (this.parentGrid) {
                    this.parentGrid.refresh();
                } else {
                    this.refresh();
                }
                return this.showDataSourceError(errorData);
                // console.log("webf error", errorData);
                // if (errorData.responseText) {
                //    Gears.notification({
                //        title: "Server Error",
                //        content: errorData.responseText,
                //        // contentFormat: "raw",
                //        classNames: "error-dialog",
                //    });
                // } else {
                //     this.showDataSourceError(errorData);
                // }
            });
    }

    previewEmail(emailData: any, source: string | AnyLazySource): Promise<Model> {
        return previewEmail(emailData, this.selectedRecord!, source);
    }

    sendEmail(data: Model, options: object = {}): Promise<any> {
        return this.callWebFunction(
            'send_related_email',
            _.defaults({}, options, {
                dataToSend: { gears_email: data.toJSON ? data.toJSON() : data },
                type: 'record',
            }),
        );
    }

    lookupLabelUrl(options: any): string {
        const optionsObject = JSON.parse(decodeURIComponent(options));
        const { field, url } = optionsObject;
        const chosen = this.get('selectedRecord.' + field);
        if (chosen && parseInt(chosen, 10) > 0) {
            return url + '#edit' + chosen;
        } else {
            return url + '';
        }
    }

    resize() {
        if (typeof this.resizeGrid === 'function') {
            this.resizeGrid();
        }
        if (typeof this.resizeDetail === 'function') {
            this.resizeDetail();
        }
    }

    resizeDetail() {
        let detailWidget;
        return (detailWidget = $('#' + this.config.tableName() + '-detail-widget'));
    }

    defaultSortButton() {
        return this.dataSource.sort(this.config.data_source.sort);
    }

    /**
     * @deprecated
     */
    showDataSourceError(it: any) {
        return handleError(it);
    }

    /**
     * @deprecated
     */
    showServerError(it: any) {
        return handleError(it);
    }

    onChange(e: kendo.data.ObservableObjectEvent) {
        if (e.field === 'fullscreen') {
            this.fullscreenColumns();
        }
        if (e.field === 'dataLoading') {
            this.setDataLoadingIndicator();
        }
        if (/selectedRecord/.exec(e.field as string)) {
            $('span.k-tooltip-validation').hide();
            return _.each(this.children, c => {
                c.set('selectedParentRecord', this.selectedRecord);
            });
        }
    }

    isNestedInModal() {
        return !!$('.modal-dialog ' + this.config.tableDomId()).length;
    }

    setupFavicon(): void {
        const icon = this.config.icon;
        if (this.parentGrid || !icon || (global as any).__favicon) {
            return;
        }
        return setGearsFavicon(icon);
    }

    nestedModalInitialize(): void {
        if (!this.isNestedInModal()) {
            return;
        }
        const thisChild: ChildGridController = this as ChildGridController;
        const parentG = thisChild.parentGrid;
        parentG.setupModalForNestedGrids();
        thisChild.editRecord = _.wrap(this.editRecord, (old: () => any) => {
            parentG.saveRecord(undefined, { dialog: false });
            parentG.hideModal();
            old.call(thisChild);
            return thisChild.parentGrid.saveModal({ dialog: false }).then(old.call(this));
        });
        // this.addRecordButton = _.wrap(this.addRecordButton, (old: () => any) => {
        //     return thisChild.parentGrid.saveModal({dialog: false}).then(old.call(this));
        // });
        const modalDomId = this.config.modalDomId();
        $(modalDomId).on('hidden.bs.modal', () => {
            $(modalDomId).removeClass('fade');
            thisChild.parentGrid.editRecordButton();
            thisChild.parentGrid.refreshChildren();
            return $(thisChild.parentGrid.config.modalDomId()).addClass('fade');
        });
    }

    // public setupFilters(): void {
    //     if (!this.get('filters_done')) {
    //         try {
    //             if ((this.gearsFilter) != null) {
    //                 this.gearsFilter.setupFilterMenu();
    //             }
    //         } catch (e) {
    //             console.log("Could not set up filter menu", e);
    //         }
    //         return this.set("filters_done", true);
    //     };
    // }

    setDataLoadingIndicator() {
        $(this.config.widgetDomId()).toggleClass('gears-loading', this.get('dataLoading'));
        return $(this.config.modalDomId())
            .find('.gears-modal')
            .toggleClass('gears-loading', this.get('dataLoading'));
    }

    doSingleRecordView() {
        const hash = window.location.hash;
        const editMatch = hash.match(/edit=?([\w-]+)/);
        const idString = editMatch && editMatch[1];
        if (idString && this.parentGrid == null) {
            const startingRecordId = idString.match(/\D+/) ? idString : parseInt(idString);
            this.startingRecordId = startingRecordId;
            this.gearsFilter.setFilter(
                {
                    field: 'id',
                    operator: 'eq',
                    value: startingRecordId,
                },
                'ID: ' + startingRecordId,
            );
            this.single_record_loading = true;
            location.hash = '';
        }
        if (window.location.hash.match(/new/) && this.parentGrid == null) {
            this.addRecordModal();
            return (location.hash = '');
        }
    }

    setupContextMenu() {
        if (location.hash.match(/rightclick/i)) {
            return;
        }
        $(this.config.tableDomId() + ' tbody').contextmenu({
            before: (e: Event) => {
                console.log('Before click context menu');
                let field, $contextMenu, row;
                field = $(e.target)
                    .closest('td')
                    .data().field;
                $contextMenu = $(this.config.contextmenuDomId());
                $contextMenu.find('[data-field]').each(() => {
                    $(this)
                        .closest('li')
                        .hide();
                });
                $contextMenu
                    .find('[data-field="' + field + '"]')
                    .closest('li')
                    .show();
                row = $(e.target).closest('tr');
                if (row.hasClass('k-grouping-row')) {
                    row = row.next();
                    this.gearsGrid.select(row);
                    return false;
                } else {
                    this.gearsGrid.select(row);
                    console.log('Context menu ready');
                    return true;
                }
            },
            target: this.config.contextmenuDomId(),
        } as any);
        return $('.' + this.config.tableName() + '-contextmenu').contextmenu({
            target: this.config.contextmenuDomId(),
        } as any);
    }

    readLookups(): Promise<any> {
        // const this$ = this;
        if (!this.parentGrid) {
            return Promise.resolve($.post(`/${this.config.tableName()}/lookups.json`)).then(data => {
                gearsState.setState(data);
                this.refreshGrid();
            });
        }
        return Promise.reject(null);
    }

    subscribeToLookups(): void {
        if (this.parentGrid || !this.config.subscription.lookups) {
            return;
        }
        const typedName = underscore(this.config.modelName());
        this.lookupSubscription = cable.subscriptions.create(
            {
                channel: 'LookupChannel',
                typed_id: typedName,
            },
            {
                connected: () => console.log('Subscribed To Lookup Notifications'),
                disconnected: undefined as any,
                received: a => {
                    console.log('Reading Lookups');
                    this.readLookups();
                },
            },
        );
    }

    // public inlineEditCommands(row: any) {
    //     return "<span class='gears-grid-edit'><i class='fa fa-edit fa-lg'></i></span><span class='gears-grid-save'><i class='fa fa-save fa-lg'></i></span><span class='gears-grid-cancel'><i class='fa fa-ban fa-lg'></i></span>";
    // }

    refreshLookups(): void {
        _.each(this.children, (c: GridController) => c.refreshLookups());
        Gears.replaceLookupHeaders();
    }

    isVisible(): boolean {
        return this.gearsGrid && $(this.gearsGrid.element).is(':visible');
    }

    initialize(): void {
        this.importConfig();
        if (typeof this.customInitialize === 'function') {
            try {
                this.customInitialize();
            } catch (e) {
                console.log('Error running customInitialize for grid: ', this, e);
                handleError(e);
            }
        }
        if (this.config.has_saved_filters) {
            this.gearsFilter = addGearsFilter(this);
        }
        if (this.config.model_reports) {
            addGridReporter(this, this.config.model_reports);
        }
        addGridTabs(this);
        this.gridActionSubscriber = new GridActionSubscriber(this);
        $(window).resize(this.resize);
        // this.readLookups();
        if (this.config.lookups) {
            console.log('Code based lookups are deprecated');
            gearsState.lookups.setLookups(this.config.lookups);
        }
        if (this.config.showPageId) {
            this.setupShowPage();
        } else {
            this.setupGrid();
            if (this.gearsGrid) {
                addGridEditPopovers(this);
                if (typeof this.setupGridHeader === 'function') {
                    this.setupGridHeader();
                }
                if (this.config.multi_select) {
                    addMultiSelect(this);
                }
                this.setupContextMenu();
                this.setupTab();
                addGridKeyboard(this);
                this.gearsGrid.element
                    .closest('.jarviswidget')
                    .find('.jarviswidget-fullscreen-btn')
                    .click(() => _.defer(() => this.testFullscreen()));
            }
        }
        if (typeof this.setupDetailView === 'function') {
            this.setupDetailView();
        }
        if (this.config.has_modal) {
            this.setupModalView();
        }
        this.setupFavicon();
        this.doSingleRecordView();
        kendo.bind($(this.config.widgetDomId()), this);
        this.gearsFilter && this.gearsFilter.setupFilterMenu();
        this.gearsDataSource = new GearsDataSource(this.config, this);
        this.dataSource.bind('error', this.showDataSourceError);
        this.nestedModalInitialize();
        (global as any).pageSetUp();
        this.bind('change', (it: any) => this.onChange(it));
        this.resize();
        this.subscribeToLookups();
    }

    refreshModalButton(minTime?: number, confirmed?: boolean) {
        let ref$;

        // console.log("Record Changed?", this.recordUnchanged(), minTime, confirmed);
        if (this.selectedRecord && this.selectedRecord.isNew()) {
            return;
        }
        return Promise.try(() => {
            let minTime;
            this.set('dataLoading', true);
            minTime = Promise.delay(minTime || 500);
            if (confirmed || this.recordUnchanged()) {
                return;
            }
            return dialog({
                buttons: [
                    { name: 'Cancel', reject: true, value: 'cancel' },
                    { name: 'Save Changes', value: 'save' },
                    { name: 'Reload Record', value: 'reload' },
                ],
                content: 'Save changes or reload record?',
                title: 'Refreshing Edit Form... Changes Detected',
                type: 'warning',
            }).then((result: any) => {
                console.log('Doing refresh comfirm', result);
                if (result.button === 'save') {
                    return this.saveRecord().catch(Gears.handleError);
                } else {
                    return result;
                }
            });
        })
            .then(() => this.gearsDataSource.loadFreshRecord(this.selectedRecord as any))
            .then((it: any) => {
                this.set('selectedRecord', it);
            })
            .finally(() => minTime)
            .then(() => {
                this.readLookups();
            })
            .catch(() => {})
            .finally(() => this.set('dataLoading', false));
    }

    saveRecord(record?: Model, options?: { dialog: any }): Promise<kendo.data.Model> {
        if (!record) {
            if (!this.selectedRecord) {
                throw new ValidationError('Attempting to save without record');
            }
            record = this.selectedRecord;
        }
        return this.gearsDataSource
            .saveRecord(record)
            .then((savedRecord: kendo.data.Model) => {
                this.set('selectedRecord', savedRecord);
                if (this.afterSave) {
                    this.afterSave(savedRecord);
                }
                const $row = $('[data-id="#{record.id}"]');
                if ($row.length) {
                    this.gearsGrid.select($row);
                    if (!$row.is(':visible')) {
                        $row[0].scrollIntoView();
                    }
                }
                // close old errors;
                $('.botClose').each((index, element) => {
                    if (!$(element).closest('.keep').length) {
                        $(element).click();
                    }
                });
                (!options || options.dialog !== false) &&
                    Gears.notification({
                        content: "<i class='fa fa-save' id='alertboxsmall'/> Save Successful</i>",
                        displayType: 'bigBox',
                        icon: 'fa-check-square',
                        messageType: 'success',
                        timeout: 4000,
                        title: 'Record Updated',
                    });
                return savedRecord;
            })
            .catch(e => {
                Gears.handleError(e);
                throw e;
            });
    }

    /**
     * Handles inline editing of cells on the grid. Currently only supports dates.
     * @param event
     */
    inlineEditClick(event: JQuery.Event<HTMLElement, null>): void {
        const $td = $(event.target).closest('td');
        const field: string = $td.data('field');
        $td.removeAttr('inline-edit');
        const selectedRecord = this.get('selectedRecord');
        const currentValue = selectedRecord.get(field);
        console.log('Inline Edit Click', event);
        console.log('DATA: ', field, selectedRecord, currentValue);
        Gears.inlineDate($td[0], currentValue, {})
            .then((newDate: Date) => {
                console.log('Date changed to: ', newDate);
                selectedRecord.set(field, newDate);
                this.saveRecord(selectedRecord);
            })
            .catch(() => {
                this.gearsGrid.refresh();
            });
    }

    setupShowPage(): Promise<void> {
        console.log('Setup show page called');
        Promise.delay(10)
            .then(() => this.gearsDataSource.loadFreshRecord(this.config.showPageId as string))
            .then((data: Model | null) => {
                console.log('Fetched', data);
                this.set('selectedRecord', data);
                Gears.resizeAllGrids();
                this.refreshChildren();
            });
    }

    setupModalView(): void {
        try {
            const containerId = this.config.showPageId ? this.config.gridContainer() : this.config.editContainerDomId();
            const editTemplate = this.config.showPageId ? this.config.showTemplate : this.config.editTemplate;
            if (React.Component.isPrototypeOf(editTemplate)) {
                return this.setupReactModalView();
            }
            this.modalView = new kendo.View(editTemplate as string, {
                evalTemplate: true,
                model: this,
            });
            this.modalView.render($(containerId));
        } catch (e) {
            console.error('Error rendering modal for grid:', this, this.config.editTemplate, e);
        }
    }

    setupReactModalView(): void {
        ReactDOM.render(React.createElement(observer(this.config.editTemplate as IReactComponent<any>), { grid: this }), $(this.config.editContainerDomId())[0]);
    }

    refreshIfVisible(): void {
        if (this.gearsGrid && this.gearsGrid.element && $(this.gearsGrid.element).is(':visible')) {
            this.refresh();
        }
    }

    refreshGrid(): void {
        this.gearsGrid && this.gearsGrid.refresh();
        if (this.children) {
            _.each(this.children, (c: any) => c.refreshGrid());
        }
    }

    refresh(): Promise<any> {
        if (this.children) {
            this.refreshChildren();
        }
        return Promise.resolve(this.dataSource.fetch());
    }

    refreshChildren(): void {
        _.each(this.children, (c: GridController) => c.refreshIfVisible());
    }

    refreshGridWithoutScrolling(): void {
        (this.dataSource as any)._change();
        // const {gearsGrid} = this;
        // const scrollTop = gearsGrid.content[0].scrollTop;
        // gearsGrid.refresh();
        // _.defer(() => {
        //     gearsGrid.content[0].scrollTop = scrollTop;
        // });
    }

    resizeChildren(): void {
        _.each(this.get('children'), (c: GridController) => c.resizeGrid());
    }

    setupDetailView(): void {
        // console.log("Setting up detail view", this);
        const container = $('#' + this.config.detailContainerDomId());
        // console.log("Setting up detail view", this,  container);
        if (container.length === 0) {
            return;
        }
        const detailView = new kendo.View(this.config.detailTemplate, {
            evalTemplate: true,
            model: this,
            wrap: false,
        });
        detailView.render($(container));
        $('a[data-toggle="tab"]').on('shown.bs.tab', e => {
            this.resizeGrid();
            this.resizeChildren();
        });
        const detailFullscreen = '#' + this.config.detailContainerDomId() + ' .jarviswidget-fullscreen-btn';
        $(detailFullscreen).click(e => {
            Gears.resizeAllGrids();
        });
    }

    gearsUniqueIdPerModel(tabName: string): string {
        if (this.parentGrid) {
            return `${this.parentGrid.config.modelName()}-${this.config.modelName()}-${tabName}`;
        } else {
            return `${this.config.modelName()}-${tabName}`;
        }
    }

    gearsTabPaneId(tabName: string): string {
        return this.gearsUniqueIdPerModel(tabName);
    }

    gearsTabPaneHref(tabName: string): string {
        return '#' + this.gearsTabPaneId(tabName);
    }

    /**
     * @deprecated
     * @returns {number}
     */
    setupValidation(): void {
        return;
        // return _.defer(function(){
        //     return this.formValidator = $(this.config.modalDomId()).kendoValidator((typeof this.gridValidation == 'function' ? this.gridValidation() : void 8) || this.config.gearsModel().validationConfig()).data("kendoValidator");
        // });
    }
}

export class ChildGridController extends GridController {
    parentGrid: GridController;

    constructor(config: GridConfig, parentGrid: GridController) {
        super(config);
        this.parentGrid = parentGrid;

        this.parentGrid.selectRecord = _.wrap(this.parentGrid.selectRecord, (func: Function) => {
            if (!func.call(parentGrid)) {
                return;
            }
            this.set('selectedRecord', null);
            this.dataSource.data([]);
            if (this.isVisible()) {
                this.dataSource.fetch(() => this.gearsGrid.select(this.gearsGrid.element.find('tr:not(.k-grouping-row):eq(1)')));
            }
            return _.each(this.get('children'), c => {
                c.dataSource.data([]);
                c.set('selectedRecord', null);
                _.each(c.get('children'), it => {
                    it.dataSource.data([]);
                });
                c.refreshIfVisible();
            });
        });

        this.dataSource = Gears.kendoDataSource(this.config, () => this.parentGrid.get('selectedRecord.id'));

        this.parentGrid.set(this.config.viewModelName(), this);
        this.addRecord = _.wrap(this.addRecord, baseAdd => {
            baseAdd();
            if (this.config.parent_id_field != null) {
                (this.selectedRecord as Model).set(this.config.parent_id_field, (this.parentGrid.selectedRecord as any).id);
            }
        });
    }

    resizeGrid(): void {
        super.resizeGrid();
        const grid = this.tableDomElement();
        const tabContent = grid.closest('.tab-content');
        if (!tabContent.is('.fixed-height')) {
            Gears.resizeFillRemainingHeight(null, tabContent, 0);
        }
    }
}

export function kendoGrid(config: GridConfig): GridController {
    return new GridController(config);
}

export function childGrid(config: GridConfig, parentGrid: GridController): ChildGridController {
    return new ChildGridController(config, parentGrid);
}

export const parentGrid = kendoGrid;
