import Class from 'classnames';
import { bind } from 'decko';
import { observer } from 'mobx-react';
import * as React from 'react';
import MenuState from '../gears/GearsState/MenuState';
import styles from './Sidebar.scss';
import SidebarHeader, { IHeaderData } from './SidebarHeader';
import SidebarItem, { ISidebarItemRef } from './SidebarItem';

export { IHeaderData };

interface ISidebarProps {
    menuState: MenuState;
    open?: boolean;
    onBackdropClicked: () => void;
}

@observer
export default class Sidebar extends React.Component<ISidebarProps> {
    private itemRefs: ISidebarItemRef[] = [];

    @bind
    private onClick(): void {
        for (let i = 0; i < this.props.menuState.menuItems.length; i++) {
            const item: ISidebarItemRef = this.itemRefs[i];
            item.hide();
        }
    }

    render(): JSX.Element {
        const { header, footer } = this.props.menuState;
        const sidebar = this.props.menuState.menuItems;

        return (
            <div>
                {this.props.open && <div className={Class(styles.backdrop)} onClick={this.props.onBackdropClicked} />}
                <div className={Class(styles.sidebar, { [styles.open]: this.props.open })}>
                    <div>
                        {this.props.open && (
                            <>
                                <SidebarHeader header={header} />
                                {sidebar.map((item, index) => (
                                    <SidebarItem ref={ref => (this.itemRefs[index] = ref as ISidebarItemRef)} onClick={this.onClick} key={item.title} {...item} />
                                ))}
                            </>
                        )}
                    </div>
                    <div className={Class(styles.footer)}>
                        <span dangerouslySetInnerHTML={{ __html: footer }} />
                    </div>
                </div>
            </div>
        );
    }
}
