import { parse } from "jju/lib/parse";
import React from "react";
import DiffChanges from "../../react-components/DiffChanges";
import Tabs from "../../react-components/react-tabs";

interface IDiffValuesContainerProps {
    inputA: string;
    inputB: string;
}

const DiffValuesContainer = React.memo(function DiffValuesContainerComponent(props: IDiffValuesContainerProps): JSX.Element {
    return (
        <div>
            <div>
                <i className="fa fa-lg fa-times txt-color-red" /> Old Value
                <pre>
                    <span>{props.inputA}</span>
                </pre>
            </div>
            <div>
                <i className="fa fa-lg fa-check txt-color-green" /> New Value
                <pre>
                    <span>{props.inputB}</span>
                </pre>
            </div>
        </div>
    );
});

type DisplayType = "bold" | "removed";

interface IDiffChangesContainerProps {
    displayType: DisplayType;
    inputA: string;
    inputB: string;
    type: "chars" | "words" | "sentences" | "json";
}

const DiffChangesContainer = React.memo(function DiffChangesContainerComponent(props: IDiffChangesContainerProps): JSX.Element {
    const [displayType, setDisplayType] = React.useState(props.displayType);

    const changeDisplay = React.useCallback((e) => {
        setDisplayType(e.currentTarget.value);
    }, []);

    return (
        <div>
            <div className="full-width">
                <i className="fa fa-lg fa-check txt-color-green" /> Changed Values
                <select defaultValue={props.displayType} onChange={changeDisplay} className="diff-select-box">
                    <option value="bold">Bold New</option>
                    <option value="removed">Show Old / New</option>
                </select>
                <div className="full-width">
                    <DiffChanges inputA={props.inputA} inputB={props.inputB} type={props.type} displayType={displayType} />
                </div>
            </div>
        </div>
    );
});

interface IChangeLogChangeDiffProps {
    changeLogChange: {
        new_value_value: () => string;
        old_value_value: () => string;
        new_value: string;
        old_raw: string;
        new_raw: string;
    };
    displayType: DisplayType;
    type: "chars" | "words" | "sentences" | "json";
    tab: number;
}

export default React.memo(function ChangeLogChangeDiff(props: IChangeLogChangeDiffProps): JSX.Element | null {
    const changeLogChange = props.changeLogChange;
    if (!changeLogChange || !changeLogChange.new_value_value) { return null; }
    const inputA = changeLogChange.old_value_value() + "";
    const inputB = changeLogChange.new_value_value() + "";

    const tabs = [
        {
            title: "Values",
            content: (<DiffValuesContainer inputA={inputA} inputB={inputB} />),
        },
    ];
    const maxLength = 4000;
    if (inputA.length < maxLength && inputB.length < maxLength) {
        tabs.unshift({
            title: "Changes",
            content: (<DiffChangesContainer displayType={props.displayType || "bold"} inputA={inputA} inputB={inputB} type={props.type || "chars"} />),
        });
    }
    // const newJson = JSON.parse(changeLogChange.new_value) || {};
    const oldJson = parse(changeLogChange.new_value) || {};
    if (typeof (oldJson) === "object") {
        tabs.push({
            title: "Raw",
            content: (<DiffValuesContainer inputA={changeLogChange.old_raw} inputB={changeLogChange.new_raw} />),
        });
    }
    return (<Tabs defaultTab={0} tabData={tabs} />);
});

