import { all_grids, GridController } from './GridController';

interface IGridKeyboard {
    keyboard_selected: boolean;
    gridKeypress(e: KeyboardEvent): void;
    keyboardSelect(diff: number): void;
    keyboardSelectLeft(): void;
    keyboardSelectRight(): void;
}

interface IGridControllerWithKeyboard extends GridController, IGridKeyboard {}

function gridKeypress(this: IGridControllerWithKeyboard, e: KeyboardEvent): void {
    let previous;
    let next;
    // console.log("gridKeypress", e, e.which || e.keyCode);
    const anyModalIsOpen = !!$('.widget-modal.in').length;
    const keyCode = e.which || e.keyCode;
    switch (false) {
        case !(keyCode === 83 && e.ctrlKey):
            if (!this.modalIsOpen()) {
                return;
            }
            $(document.activeElement).blur();
            e.preventDefault();
            this.saveModal();
            break;
        case keyCode !== 27:
            // console.log("Escape pressed");
            if (!this.modalIsOpen()) {
                return;
            }
            $(document.activeElement).blur();
            _.defer(function() {
                return this.cancelModal();
            });
    }
    if (!this.keyboard_selected) {
        return;
    }
    if (anyModalIsOpen) {
        return;
    }
    switch (true) {
        case keyCode === 38:
            e.preventDefault();
            previous = this.gearsGrid.select().prev();
            if (previous) {
                return this.gearsGrid.select(previous);
            }
            break;
        case keyCode === 40:
            e.preventDefault();
            next = this.gearsGrid.select().next();
            if (next) {
                return this.gearsGrid.select(next);
            }
            break;
        case keyCode === 37:
            e.preventDefault();
            return this.keyboardSelectLeft();
        case keyCode === 39:
            e.preventDefault();
            return this.keyboardSelectRight();
        case e.ctrlKey && keyCode === 78:
            e.preventDefault();
            return this.addRecordButton();
    }
}

function keyboardSelect(this: IGridControllerWithKeyboard, diff: number): void {
    let index = _.findIndex(all_grids, this) || 0;
    index += diff + all_grids.length;
    index %= all_grids.length;
    const newg = all_grids[index] as IGridControllerWithKeyboard;
    _.defer(() => {
        this.set('keyboard_selected', false);
        newg.set('keyboard_selected', true);
        if (!newg.isVisible()) {
            newg.keyboardSelect(diff);
        }
    });
}

export default function addGridKeyboard(gearsGrid: any): void {
    gearsGrid.bind('change', (it: any) => {
        let selected;
        if (it.field === 'keyboard_selected') {
            selected = gearsGrid.keyboard_selected;
            gearsGrid.gearsGrid.element.removeClass('gears-animation-done');
            gearsGrid.gearsGrid.element.toggleClass('gears-grid-selected', selected);
            if (selected) {
                _.delay(() => {
                    gearsGrid.gearsGrid.element.addClass('gears-animation-done');
                }, 3000);
            }
        }
    });
    gearsGrid.keypress = gridKeypress.bind(gearsGrid);
    const boundKeyboardSelect = keyboardSelect.bind(gearsGrid);
    gearsGrid.keyboardSelect = boundKeyboardSelect;
    gearsGrid.keyboardSelectRight = () => boundKeyboardSelect(1);
    gearsGrid.keyboardSelectLeft = () => boundKeyboardSelect(-1);
    $(document).keydown(gearsGrid.keypress);
    return gearsGrid.set('keyboard_selected', !gearsGrid.parentGrid);
}
