import * as monaco from "monaco-editor";
import vendor from "../../modules/vendor";
import { beautifyHtml, beautifyJs } from "../helpers/beautify";
import * as binding_gears_object from "./kendo-binding-gears-object";

function kendoMonaco() {
    binding_gears_object.kendoBindingGearsObject();
    const kendo = (global as any).kendo;
    const ui = kendo.ui;
    const Widget = ui.Widget;

    const KendoMonaco = Widget.extend({
        init(element: any, options: any): void {
            Widget.fn.init.call(this, element, options);
            if (this.options.enabled !== undefined) {
                this.options.readOnly = !this.options.enabled;
            }
            switch (this.options.mode) {
                case "html":
                case "html_ruby":
                    this.beautify = beautifyHtml;
                    this.options.mode = "html";
                    break;
                case "javascript":
                case "typescript":
                case "jsx":
                case "tsx":
                    this.options.mode = "javascript";
                    break;
                case "json":
                case "json5":
                case "object":
                    this.beautify = beautifyJs;
                    this.options.mode = "javascript";
                    break;
            }

            const t = async () => {
                const monacoImport = await vendor.monaco;
                monacoImport.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
                    noSemanticValidation: false,
                    noSyntaxValidation: true,
                });
                const child = document.createElement("div");
                child.style.height = "100%";
                this.element[0].style.width = "99.5%";
                this.element[0].style.padding = "0";
                this.element[0].appendChild(child);
                const mModel = monaco.editor.createModel("", this.options.mode);
                this.editor = monacoImport.editor.create(child, {
                    model: mModel,
                    automaticLayout: true,
                    acceptSuggestionOnEnter: false,
                    minimap: { enabled: false },
                    lineNumbers: "off",
                    autoIndent: true,
                    formatOnPaste: false,
                    formatOnType: false,
                    showUnused: true,
                });
                this.editor.onDidBlurEditorWidget(() => {
                    this.trigger("change");
                });
            };
            t();
        },
        options: {
            name: "Ace", // Temporary to test over existing Ace Editors
            mode: "javascript",
            tabSize: 2,
            beautify: null,
            readOnlyFlag: false,
        },
        refresh(): void {
            _.defer(() => {
                const elValue = this.element.value();
                return this.value(elValue != null ? elValue : "");
            });
        },
        readOnly(value?: boolean): boolean | undefined {
            if (!this.editor) {
                return false;
            }
            if (value === undefined) {
                return this.readOnlyFlag;
            } else {
                this.readOnlyFlag = value;
                (this.editor as monaco.editor.IStandaloneCodeEditor).updateOptions({
                    readOnly: value,
                });
            }
        },
        enabled(value?: boolean): boolean | undefined {
            return this.readOnly(value);
        },
        value(value?: string): string | undefined {
            if (value === undefined) {
                return this.editor.getValue().replace(/\t/, "  ");
            }
            if (value === null) {
                value = "";
            }
            if (this.beautify) {
                value = this.beautify(value);
            }
            try {
                this.editor.setValue(value);
            } catch (e) {
                // return console.log("Could not set value");
                return;
            }
        },
    });
    return ui.plugin(KendoMonaco);
}

kendoMonaco();
