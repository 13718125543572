const sources: any = {};
sources.binding_gears_object = require("./kendo-binding-gears-object");
sources.bootstrapModalSelect2 = require("./bootstrap-modal-select2");
sources.clickLogId = require("./ClickLogId");
sources.delayedDataSourceBinding = require("./delayed-data-source-binding");
sources.dynamicTooltips = require("./dynamic-tooltips");
sources.firstLineTemplate = require("./first-line-template");
sources.formPopovers = require("./form-popovers");
sources.gearsPopover = require("./gears-popover");
sources.initializers = require("./initializers");
sources.kendoBootstrapDatepicker = require("./kendo-bootstrap-datepicker");
sources.kendoGridOverrides = require("./kendo-grid-overrides");
sources.kendoReactComponents = require("./kendo-react-components");
sources.kendo_monaco = require("./KendoMonaco");
sources.kendo_codemirror = require("./kendo_codemirror");
sources.kendo_select2 = require("./kendo_select2");
sources.menuFix = require("./menu-fix");
sources.header = require("./header");
sources.startupClass = require("./startup-class");
sources.timezone = require("./Timezone");
sources.kendoEditorInsideTabFix = require("./kendo-editor-inside-tab-fix");
require("./LogoutCredentials.ts");

const initializers: {[key: string]: () => void} = _.reduce(sources, _.assign, {});
export default initializers;
