import { diffChars, diffJson, diffSentences, diffWords } from 'diff';
import * as _ from 'lodash';
import * as React from 'react';

const fnMap: { [key: string]: (a: string, b: string) => JsDiff.IDiffResult[] } = {
    chars: diffChars,
    json: diffJson,
    sentences: diffSentences,
    words: diffWords,
};

interface IDiffChangesPropTypes {
    inputA: string | number;
    inputB: string | number;
    type: 'chars' | 'words' | 'sentences' | 'json';
    displayType: 'bold' | 'removed';
}

function boldDiff(diff: JsDiff.IDiffResult[]) {
    return _(diff)
        .reject('removed')
        .map((part, index) => {
            const spanClassName = part.added ? 'diff-bold' : 'diff-unbold';
            return (
                <span key={index} className={spanClassName}>
                    {part.value}
                </span>
            );
        })
        .value();
}

function removedDiff(diff: JsDiff.IDiffResult[]) {
    return diff.map((part, index) => {
        const spanClassName = part.added ? 'diff-added' : part.removed ? 'diff-removed' : 'diff-unchanged';
        return (
            <span key={index} className={spanClassName}>
                {part.value}
            </span>
        );
    });
}

export default class DiffChanges extends React.Component<IDiffChangesPropTypes, never> {
    render() {
        return this.renderDiffItems(this.calcDiff());
    }
    private calcDiff(): JsDiff.IDiffResult[] {
        const { type, inputA, inputB } = this.props;
        return fnMap[type](inputA + '', inputB + '') as JsDiff.IDiffResult[];
    }
    private renderDiffItems(diff: JsDiff.IDiffResult[]) {
        const result = this.props.displayType === 'bold' ? boldDiff(diff) : removedDiff(diff);
        return <pre className="diff-result"> {result} </pre>;
    }

    static defaultProps: IDiffChangesPropTypes = {
        displayType: 'bold',
        inputA: '',
        inputB: '',
        type: 'chars',
    };
}
