import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is still used by DiffChanges, only
 * remove when that changes
 */
export default class Tabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tab: props.defaultTab || 0 };
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ tab: nextProps.defaultTab });
    }

    _changeTab(object, tab) {
        object.setState({ tab });
        if (this.props.onChange) {
            this.props.onChange(tab);
        }
    }

    render() {
        return (
            <div className="tab-container">
                <ul>
                    {this.props.tabData.map(function(item, index) {
                        return (
                            <li key={index} className={this.state.tab == index ? 'active' : ''} onMouseDown={event => this._changeTab(this, index)}>
                                {item.title}
                            </li>
                        );
                    }, this)}
                </ul>
                <div className="tab-values-container">{this.props.tabData[this.state.tab].content}</div>
            </div>
        );
    }

    static defaultProps = {
        defaultTab: 0,
        onChange: null,
        tabData: null,
    };

    static propTypes = {
        defaultTab: PropTypes.number,
        onChange: PropTypes.func,
        tabData: PropTypes.array.isRequired,
    };
}
