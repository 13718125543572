/// <reference types="kendo-ui"/>

import * as Promise from 'bluebird';
import { each } from 'lodash';
import { Memoize } from 'lodash-decorators';
import * as moment from 'moment-timezone';
import { DataFetchError, DeleteRecordError } from './Errors';
import { Model } from './GearsModel/Model';
import GridConfig from './GridConfig';
import { GridController } from './GridController';
import { deepPickBlanks, deepStripBlanks, isBlank } from './helpers';

declare const kendo: any;

const KendoDataSource = kendo.data.DataSource;
const kendoCollapseGroup = kendo.ui.Grid.prototype.collapseGroup;
const kendoExpandGroup = kendo.ui.Grid.prototype.expandGroup;
const kendoTrigger = kendo.data.DataSource.prototype.trigger;
const kendoAggregates = kendo.data.DataSource.prototype.aggregates;
const kendo_Params = kendo.data.DataSource.prototype._params;
const kendo_QueryProcess = kendo.data.DataSource.prototype._queryProcess;

const CHANGE = 'change';
const arrayPush = [].push;
const arrayUnshift = [].unshift;

function comparer(a: any, b: any) {
    if (!a) {
        return a === b;
    }
}

interface IKendoDataSourceWithInternals extends kendo.data.DataSource {
    _data: kendo.data.ObservableArray;
    _pristineData: object[];
    _group: Array<{ field: string }>;
    _view: Array<{ value: number | string; items: Model[] }>;
    reader: { data: (data: object[]) => any };
    _readData: (data: object[]) => any;
    _params: (data: any) => any;
    _queryProcess: (data: object[], options: object) => any;
    _change: () => void;
}

// class WrappedKendoDataSource extends kendo.data.DataSource

interface IGroupExpansions {
    [group: string]: boolean;
}

export function standardizeAllDates(data: { [p: string]: any }): { [p: string]: any } {
    _.each(data, (value: any, key: string) => {
        if (moment.isDate(value) || moment.isMoment(value)) {
            data[key] = moment(value).format('YYYY-MM-DD HH:mm Z');
        }
    });
    return data;
}

export default class GearsDataSource {
    saveActive: boolean = false;
    private readonly kendoDataSource: IKendoDataSourceWithInternals;
    private readonly model: typeof Model;
    private _fetchMoreRequest: Promise<any> = Promise.resolve('Just Starting');
    private _saveRequest: Promise<any> = Promise.resolve('Just Starting');
    private _serverTotal: number;
    private _queryConfig?: object;
    private _expandedGroups?: IGroupExpansions;
    private _loadingMessage: string = "<span class='loading-message'><i class='fal fa-spin fa-circle-notch'></i>&nbsp;&nbsp;&nbsp;Loading additional records...</span>";
    private _serverGroups: any;
    private _pagesLoaded: number = 0;
    private readonly gridConfig: GridConfig;
    private readonly grid: GridController;

    constructor(gridConfig: GridConfig, grid: GridController) {
        this.gridConfig = gridConfig;
        this.grid = grid;
        this.kendoDataSource = grid.dataSource as IKendoDataSourceWithInternals;
        this.model = gridConfig.gearsModel;
        this.clearData();
        this.wrapKendo();
        this.grid.gearsGrid && $(this.grid.gearsGrid.content).scroll(this.loadMoreIfNecessary.bind(this));
    }

    gearsInjectUpdate(items: object | object[]): Model | Model[] {
        if (_.isArrayLike(items)) {
            return _.each(items, (i: object) => this.gearsInjectUpdateItem(i));
        } else {
            return this.gearsInjectUpdateItem(items);
        }
    }

    gearsInjectUpdateItem(item: object, addToTop?: boolean): Model {
        // var addToArray, model, id, targetIndex, target, groupField, ref$, ref1$, group;
        // top == null && (top = false);
        const addToArray = addToTop ? arrayUnshift : arrayPush;
        const model: Model = new this.model(item);
        const { idField } = model;
        const id = model[idField];
        const kendoDataSource = this.kendoDataSource;
        const targetIndex = _.findIndex(kendoDataSource._data, [idField, id]);

        if (targetIndex !== -1) {
            const target: Model = kendoDataSource._data[targetIndex];
            kendoDataSource._data[targetIndex] = model;
            kendoDataSource._pristineData[targetIndex] = model.toJSON();
            return model;
        } else {
            addToArray.call(kendoDataSource._pristineData, model.toJSON());
            addToArray.call(kendoDataSource._data, model);
            const firstGroup = kendoDataSource._group[0];
            const groupField: string | null = firstGroup && firstGroup.field;
            if (groupField) {
                const group = _.find(kendoDataSource._view, {
                    value: model[groupField],
                });
                if (group) {
                    addToArray.call(group.items, model);
                }
                {
                    addToArray.call(kendoDataSource._view, { items: [model], value: model[groupField] });
                }
            } else {
                addToArray.call(kendoDataSource._view, model);
            }
            return model;
        }
    }

    expandedGroups(setValue?: IGroupExpansions): IGroupExpansions {
        const key = this.gridConfig.baseName() + '_expanded';
        if (setValue != null) {
            this._expandedGroups = setValue;
            // localStorage.setItem(key, JSON.stringify(setValue));
            return setValue;
        } else {
            if (this._expandedGroups == null) {
                // const localStorageValue = localStorage.getItem(key);
                // if (localStorageValue) {
                //     this._expandedGroups = JSON.parse(localStorageValue);
                // }
                this._expandedGroups = {};
            }
            return this._expandedGroups;
        }
    }

    collapseGroup = (row: string | JQuery<HTMLElement> | Element, persist?: boolean): void => {
        persist == null && (persist = true);
        const groupData = $(row)
            .find('.group-data')
            .data();
        if (persist && groupData) {
            const egs = this.expandedGroups() || {};
            egs[groupData.groupValue] = false;
            this.expandedGroups(egs);
        }
        if (!groupData) {
            return;
        }
        $(row).removeClass('expanded');
        $(this.grid.gearsGrid.content)
            .find("td[data-group-value='" + groupData.groupValue + "']")
            .parent()
            .addClass('collapsed');
        kendoCollapseGroup.call(this.grid.gearsGrid, row);
        this.loadMoreIfNecessary();
    };

    expandGroup = (row: string | JQuery<HTMLElement> | Element, persist?: boolean) => {
        persist == null && (persist = true);
        const $group = $(row);
        const groupData = $group.find('.group-data').data();
        if (persist) {
            if (groupData) {
                const egs = this.expandedGroups() || {};
                egs[groupData.groupValue] = true;
                this.expandedGroups(egs);
            }
        }
        $group.addClass('expanded');
        $(this.grid.gearsGrid.content)
            .find("td[data-group-value='" + (groupData != null ? groupData.groupValue : void 8) + "']")
            .parent()
            .removeClass('collapsed');
        kendoExpandGroup.call(this.grid.gearsGrid, row);
        return this.loadMoreIfNecessary();
    };

    groupStateExpanded = (row: string | JQuery<HTMLElement> | Element) => {
        const groupData = $(row)
            .find('.group-data')
            .data();
        if (!groupData) {
            return false;
        }
        return !!this.expandedGroups()[groupData.groupValue];
    };

    collapseGroups(): void {
        let openGroupingCells, i$, len$, groupCell, groupRow, expanded;
        openGroupingCells = $(this.grid.gearsGrid.content).find('.k-grouping-row > td[aria-expanded="true"]');
        for (const groupCell of openGroupingCells) {
            $(groupCell)
                .parent()
                .addClass('expanded');
        }
        if (openGroupingCells.length <= 1) {
            return;
        }
        if (!this.expandedGroups()) {
            this.expandGroup(openGroupingCells.first());
        }
        for (const groupCell of openGroupingCells) {
            groupRow = $(groupCell).parent();
            expanded = this.groupStateExpanded(groupRow);
            if (!expanded) {
                this.collapseGroup(groupRow, false);
            }
        }
    }

    _bufferRowForGroup(group: { items: Model[]; value: string; field: string; total_entries: number }) {
        if (group.items.length < group.total_entries) {
            const $groupRow = $(this.grid.gearsGrid.content)
                .find('[data-group-value="' + group.value + '"]')
                .closest('tr');
            $groupRow.addClass('load-incomplete');
            let $bottomRow;
            if (group.items.length === 0) {
                $bottomRow = $groupRow;
            } else {
                $bottomRow = $(this.grid.gearsGrid.content).find('[data-uid="' + group.items[group.items.length - 1].uid + '"]');
            }
            const colspan = $groupRow.find('td').attr('colspan');
            const $tr = $("<tr data-group-value='" + group.value + "' data-group-field='" + group.field + "' class='gears-placeholder k-grouping-row'></tr>");
            $tr.addClass($groupRow.hasClass('expanded') ? 'expanded' : 'collapsed');
            const $td = $("<td colspan='" + colspan + "' class='gears-placeholder k-grouping-row' data-group-value='" + group.value + "'>" + this._loadingMessage + '</td>');
            const missing_height = (group.total_entries - group.items.length) * 34;
            $td.height(missing_height > 1200 ? 1200 : missing_height);
            $tr.append($td);
            $bottomRow.after($tr);
        }
    }

    _placeholderRowUngrouped() {
        // var $content, $last, colspan, $tr, $td, missing_height;
        if (this.kendoDataSource.data().length < this._serverTotal) {
            const $content = $(this.grid.gearsGrid.content);
            const $last = $content.find('tr:last');
            const colspan = $last.find('td').length;
            const $tr = $("<tr class='expanded gears-placeholder k-grouping-row'></tr>");
            const $td = $("<td colspan='" + colspan + "' class='gears-placeholder k-grouping-row' >" + this._loadingMessage + '</td>');
            const missing_height = (this._serverTotal - this.kendoDataSource.data().length) * 34;
            $td.height(missing_height > 1200 ? 1200 : missing_height);
            $tr.append($td);
            return $last.after($tr);
        }
    }

    markIncompleteLoad() {
        const view = this.kendoDataSource.view() as any;
        if (!(view.length > 0)) {
            return;
        }
        if (this._serverGroups) {
            for (const group of view) {
                this._bufferRowForGroup(group);
            }
        } else {
            this._placeholderRowUngrouped();
        }
        this.loadMoreIfNecessary();
    }

    saveServerData(data: any) {
        if (data.group) {
            this._serverGroups = data.group;
            this.grid.set('groupData', data.group);
        }
        if (data.total_entries != null) {
            this._serverTotal = data.total_entries;
        }
        return (this._pagesLoaded = parseInt(data.page));
    }

    fillServerGroupData(data: any) {
        let calcdata, i$, ref$, len$, serverGroup, calcgroup, items, res;
        if (!this._serverGroups) {
            return data;
        }
        data.total_entries = this._serverTotal;
        calcdata = data.data;
        data.data = [];
        for (const serverGroup of this._serverGroups) {
            calcgroup = _.find(calcdata, (it: any) => it.value === serverGroup.value);
            items = (calcgroup != null ? calcgroup.items : void 8) || [];
            res = {
                aggregates: serverGroup.aggregates,
                field: serverGroup.field,
                hasSubgroups: false,
                items,
                total_entries: serverGroup.total_entries,
                value: serverGroup.value,
            };
            res.aggregates.total_entries = serverGroup.total_entries;
            data.data.push(res);
        }
        return data;
    }

    @Memoize()
    defaultBlanks(): any {
        return deepPickBlanks(new this.gridConfig.gearsModel().toJSON());
    }

    recordChanges(record: Model): any {
        // console.log("Record Changes:", record);
        if (!record) {
            return {};
        }
        const idField = this.model.idField;
        const newData = typeof record.toJSON === 'function' ? record.toJSON() : record;
        const pristineData = _.find(this.kendoDataSource._pristineData, [idField, record[idField]]) || this.defaultBlanks();
        const withoutBlanks = deepStripBlanks(newData);
        const changedData = _.transform(
            newData,
            (a: any, value: any, key: string) => {
                if (key[0] === '_' || key === 'webfront_relations') {
                    return;
                }
                if (pristineData != null) {
                    if (_.isEqualWith(pristineData[key], value, comparer)) {
                        return;
                    }
                }
                a[key] = isBlank(value) ? null : withoutBlanks[key];
            },
            {},
        );
        console.log('Changed Data', {
            cd: changedData,
            nd: newData,
            pd: pristineData,
            wd: withoutBlanks,
        });
        return changedData;
    }

    deleteById(id: number | string) {
        return Promise.resolve(
            $.ajax({
                dataType: 'json',
                type: 'DELETE',
                url: this.gridConfig.destroyUrl(id),
            }),
        )
            .catch(result => {
                throw new DeleteRecordError(result.responseJSON);
            })
            .then(data => {
                let oldItem;
                if (data.error) {
                    throw new DeleteRecordError(data.error);
                }
                oldItem = this.kendoDataSource.get(id);
                if (oldItem) {
                    return this.kendoDataSource.pushDestroy(oldItem);
                }
            });
    }

    deleteRecord(recordToDelete: Model) {
        return this.deleteById(recordToDelete.id);
    }

    kendoHeaderData() {
        const ds = this.grid.dataSource;
        const data = {
            aggregate: this.gridConfig.data_source.aggregate,
            filter: ds.filter(),
            page: this._pagesLoaded + 1,
            pageSize: this.gridConfig.data_source.pageSize,
            sort: ds.sort(),
        };
        return data;
    }

    getParentId() {
        return _.get(this.grid, 'parentGrid.selectedRecord.id');
    }

    fetchAllData() {
        const headerData = _.cloneDeep(this._queryConfig);
        delete headerData.pageSize;
        return this.fetchMoreData(headerData);
    }

    fetchGroupData(groupData: { [key: string]: boolean }) {
        const headerData = _.cloneDeep(this._queryConfig);
        const groupItems = _.find(this.kendoDataSource.view(), {
            value: groupData.groupValue,
        }).items;
        const groupIds = _(groupItems)
            .map('id')
            .uniq()
            .value();
        headerData.without_ids = groupIds;
        headerData['expanded-groups'] = groupData;
        return this.fetchMoreData(headerData);
    }

    fetchMoreData(headerData: any) {
        if (!(this.kendoDataSource.data().length < this._serverTotal)) {
            return Promise.resolve('Already Loaded All Records');
        }
        if (!this._fetchMoreRequest.isFulfilled()) {
            return this._fetchMoreRequest;
        }
        return (this._fetchMoreRequest = Promise.resolve(headerData)
            .then(headerData => {
                if (headerData == null) {
                    headerData = this._queryConfig;
                    headerData.offset = this.kendoDataSource._data.length;
                }
                return $.ajax({
                    data: headerData,
                    dataType: 'json',
                    type: 'POST',
                    url: this.gridConfig.readUrl(this.getParentId()),
                });
            })
            .then(data => {
                if (data.error) {
                    throw new DataFetchError(data.error);
                }
                this.saveServerData(data);
                return this.incrementalInject(data.data);
            }));
    }

    incrementalInject(data: object[], addToTop?: boolean, per: number = 50) {
        per == null && (per = 50);
        return _(data)
            .chunk(per)
            .each((ch: object[]) => {
                this.gearsInjectUpdate(ch);
                return this.kendoDataSource._change();
            });
    }

    _createUrl(): string {
        return this.gridConfig.createUrl(this.getParentId());
    }

    saveRecord(record: Model) {
        if (this.grid.saveActive || (this._saveRequest && this._saveRequest.isPending())) {
            return Promise.reject('Save in Progress');
        }
        this.grid.set('saveActive', true);
        return (this._saveRequest = Promise.try(() => {
            const isNew = record.isNew();
            const data = JSON.stringify(this._wrapData(this.recordChanges(record)));
            return $.ajax({
                contentType: 'application/json',
                data,
                dataType: 'json',
                type: isNew ? 'POST' : 'PUT',
                url: isNew ? this._createUrl() : this.gridConfig.updateUrl(record.id),
            });
        })
            .then(data => {
                let newRec;
                if (data.error) {
                    throw data;
                }
                console.log('save success, new record:');
                console.log(data);
                this.grid.set('selectedRecord', null);
                newRec = this.gearsInjectUpdateItem(data, true);
                this.kendoDataSource._change();
                this.grid.set('selectedRecord', newRec);
                this.kendoDataSource.cancelChanges();
                return newRec;
            })
            .finally(() => {
                console.log('setting save false');
                return this.grid.set('saveActive', false);
            }));
    }

    setGrouping(groupConfig: object): void {
        this.clearData();
        if (!groupConfig) {
            return this.kendoDataSource.group([]);
        }
        if (typeof groupConfig === 'string') {
            groupConfig = {
                dir: 'asc',
                field: groupConfig,
            };
        }
        this.kendoDataSource.group([groupConfig]);
    }

    clearData(): void {
        this._serverTotal = 0;
        this._serverGroups = null;
        this._pagesLoaded = 0;
        this._expandedGroups = undefined;
        this._queryConfig = undefined;
    }

    scrollIsNearBottom(): false | JQuery<HTMLElement> {
        const content = $(this.grid.gearsGrid.content);
        const displayHeight: number = content.height() as number;
        const scrollBottom = content[0].scrollTop + displayHeight;
        const lastRow = $(this.grid.gearsGrid.content)
            .find('tr:not(.collapsed) td.gears-placeholder')
            .first()
            .parent();
        if (!(lastRow.length > 0)) {
            return false;
        }
        const lastRowTop = lastRow.position().top;
        // console.log("scrollIsNearBotton", scrollBottom, lastRowTop);
        if (lastRowTop < scrollBottom + 1000) {
            return lastRow;
        } else {
            return false;
        }
    }

    loadMoreIfNecessary(event?: any): Promise<any> {
        return this._fetchMoreRequest.bind(this).then(function() {
            const $scrollRow = this.scrollIsNearBottom();
            if ($scrollRow) {
                const groupData = $scrollRow.data();
                if ((groupData != null ? groupData.groupValue : void 8) != null) {
                    return this.fetchGroupData(groupData);
                } else {
                    return this.fetchMoreData();
                }
            }
        });
    }

    loadFreshRecord(toLoad?: number | string | Model): Promise<Model | null> {
        if (toLoad == null) {
            toLoad = this.grid.get('selectedRecord.id');
        }
        const id: number | string | null = typeof toLoad === 'object' ? toLoad.id : toLoad;
        if (id == null) {
            return Promise.resolve(null);
        }
        return Promise.resolve(
            $.ajax({
                dataType: 'json',
                type: 'GET',
                url: this.gridConfig.loadSingleUrl(id),
            }),
        ).then((data: any) => {
            let newRec;
            if (data.error) {
                throw new DataFetchError(data.error);
            }
            newRec = this.gearsInjectUpdateItem(data);
            this.kendoDataSource?._change();
            newRec.trigger('change');
            return newRec;
        });
    }

    withoutKendoTriggers(func: () => any): any {
        this.kendoDataSource.trigger = _.noop;
        const result = func();
        this.kendoDataSource.trigger = kendoTrigger;
        return result;
    }

    private wrapKendo() {
        this.kendoDataSource._readData = data => {
            this.saveServerData(data);
            return this.kendoDataSource.reader.data(data);
        };

        this.kendoDataSource.aggregates = () => {
            const result = kendoAggregates.call(this.kendoDataSource);
            result.total_entries = this._serverTotal;
            return result;
        };

        this.kendoDataSource._params = data => {
            const originalServerGrouping = this.kendoDataSource.options.serverGrouping;
            this.kendoDataSource.options.serverGrouping = true;
            const result = kendo_Params.call(this.kendoDataSource, data);
            this.kendoDataSource.options.serverGrouping = originalServerGrouping;
            this._queryConfig = result;

            const fixFields = (value: any, key: string, object: any) => {
                if (key === 'field' && typeof value === 'object') {
                    object.field = value.fieldName;
                } else if (typeof value === 'object') {
                    each(value, fixFields);
                }
            };
            each(result, fixFields);

            return result;
        };

        this.kendoDataSource._queryProcess = (data: object[], options: object) => {
            let res = kendo_QueryProcess.call(this.kendoDataSource, data, options);
            res = this.fillServerGroupData(res);
            return res;
        };

        if (this.grid.gearsGrid) {
            this.grid.gearsGrid.collapseGroup = this.collapseGroup;
            this.grid.gearsGrid.expandGroup = this.expandGroup;
        }
    }

    private _wrapData(data: { [key: string]: any }) {
        standardizeAllDates(data);
        return { [this.gridConfig.instanceName()]: data };
    }
}
