import ActionBar from "commonui/ActionBar";
import ActionItem from "commonui/ActionItem";
import Button from "commonui/Button";
import createCustomDialog from "commonui/CustomDialog";
import SnackBar from "commonui/SnackBar";
import { bind } from "decko";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import styles from "./FilterBuilder.scss";
import FilterGroup from "./FilterGroup";

type IDataSourceFilterItem = kendo.data.DataSourceFilterItem;
type IDataSourceFilters = kendo.data.DataSourceFilters;
type IDataSourceFilter = IDataSourceFilterItem | IDataSourceFilters;


interface IFilterBuilderProps {
    filter: IDataSourceFilter;
    data: any[];
    name: string;
    resolve?: (filter: IDataSourceFilter) => void;
}

export default function createFilterBuilder(filter: IDataSourceFilter, data: any, name: string = ""): Promise<any> {
    return createCustomDialog(
        <FilterBuilder name={name} filter={filter} data={data} />
    );
}

export function defaultFilter(): kendo.data.DataSourceFilters {
    return {
        filters: [
            {
                "field": "",
                "operator": "",
                "value": "",
            },
        ],
        logic: "and",
    };
}

/**
 * A widget to create filters
 */
@inject("resolve")
@observer
class FilterBuilder extends React.Component<IFilterBuilderProps, never> {
    @observable private filter: any = {};

    constructor(props: IFilterBuilderProps) {
        super(props);

        this.filter = observable(props.filter);
    }

    public componentDidMount(): void {
        document.addEventListener("paste", this.onPaste);
    }

    public componentWillUnmount(): void {
        document.removeEventListener("paste", this.onPaste);
    }

    @bind
    @action
    private onPaste(e: ClipboardEvent): void {
        const pastedText = e.clipboardData.getData("text");
        if (pastedText) {
            try {
                const jsonString = atob(pastedText);
                const json = JSON.parse(jsonString);
                // Make sure it looks sort of like a filter
                if (json.logic && json.filters) {
                    this.filter = observable(json);
                }
            }
            catch (error) {
                // Really doesn't matter if we can't pass it, we just assume it wasn't a filter
            }
        }
    }

    @bind
    private onCopy(e: React.MouseEvent<HTMLElement>): void {
        window.clipboard.copy(btoa(JSON.stringify(this.filter)));
        SnackBar.show("Filter copied to Clipboard");
    }

    @bind
    private onCancel(): void {
        const { resolve } = this.props;
        resolve && resolve(this.props.filter);
    }

    @bind
    private onSave(): void {
        const { resolve } = this.props;
        resolve && resolve(this.filter);
    }

    public render(): JSX.Element {
        return (
            <div>
                <ActionBar title="Filter Builder" subtitle={this.props.name}>
                    <ActionItem icon="fa-clipboard" title="Copy to Clipboard" onClick={this.onCopy} />
                </ActionBar>
                <div className={styles["filter-builder"]}>
                    <FilterGroup filter={this.filter} data={this.props.data} />
                    <div className={styles["button-container"]}>
                        <Button title="Cancel" onClick={this.onCancel} />
                        <Button title="Filter" coloured onClick={this.onSave} />
                    </div>
                </div>
            </div>
        );
    }
}
