import Button from 'commonui/Button';
import gearsDialog from "commonui/Dialog";
import Input from 'commonui/Input';
import { bind } from 'decko';
import * as $ from "jquery";
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from "react";
import GearsState from "../../gears/GearsState/GearsState";
import styles from "./AccountSettings.scss";

@inject("resolve")
@observer
export default class AccountSettings extends React.Component<{ gearsState: GearsState; resolve: (result?: any) => void }, never> {
    @observable private newPassword = '';
    @observable private confirmPassword = '';
    @observable private newPasswordError = '';
    @observable private confirmPasswordError = '';
    @observable private currentPassword = '';

    private get canSave(): boolean {
        if (!this.currentPassword && !this.props.gearsState.user.roles.administrator) {
            return false;
        }
        if (!this.newPassword || !this.confirmPassword) {
            return false;
        }
        if (this.newPassword !== this.confirmPassword) {
            return false;
        }
        return true;
    }

    @bind
    private catchSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        $.ajax({
            url: `/users/${this.props.gearsState.user.id}`,
            method: "PUT",
            data: {
                user: {
                    current_password: this.props.gearsState.user.roles.administrator ? undefined : this.currentPassword,
                    password: this.newPassword,
                },
            }
        }).then((result) => {
            if (typeof result === "object") {
                this.props.resolve();
                gearsDialog({
                    title: "Password successfully changed",
                    buttons: ["OK"],
                });
            } else {
                gearsDialog({
                    title: "Error",
                    content: result,
                    buttons: ["OK"],
                });
            }
        });
    }

    @bind
    @action
    private onBlurNewPassword(): void {
        this.newPasswordError = "";
        if (this.newPassword.length < 6 || this.newPassword.toLowerCase() === this.newPassword) {
            this.newPasswordError = "Password must be at least 6 characters long and must not consist solely of lowercase letters";
        }
    }

    @bind
    @action
    private onBlurConfirmPassword(): void {
        const passwordMatch = this.newPassword === this.confirmPassword;
        this.confirmPasswordError = passwordMatch ? "" : "Passwords do not match";
    }

    public render(): JSX.Element {
        const {auth} = this.props.gearsState;
        return (
            <div className={styles["account-settings"]}>
                <form
                    onSubmit={this.catchSubmit}
                >
                    <fieldset>
                        <legend>Change Password</legend>
                        <Input
                            label="Current Password"
                            observable={this}
                            value="currentPassword"
                            password
                            name="current_password"
                        />
                        <Input
                            label="New Password"
                            observable={this}
                            value="newPassword"
                            password
                            name="new_password"
                            error={this.newPasswordError}
                            onBlur={this.onBlurNewPassword}
                        />
                        <Input
                            label="Confirm Password"
                            observable={this}
                            value="confirmPassword"
                            password
                            error={this.confirmPasswordError}
                            onBlur={this.onBlurConfirmPassword}
                            name="confirm_password"
                        />
                    </fieldset>
                    <div>
                        <Button
                            title="Cancel"
                            flat
                            onClick={this.props.resolve}
                        />
                        <Button
                            title="Save"
                            disabled={!this.canSave}
                            coloured
                            type="submit"
                            className="blue"
                        />
                    </div>
                </form>
                {auth.has('google') && <a href="/auth/google_oauth2?connect=connect">Connect to Google Account</a>}<br/>
                {auth.has('facebook') && <a href="/auth/facebook?connect=connect">Connect to Facebook Account</a>}
            </div>
        );
    }
}
