/// <reference path="./global.d.ts" />
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './setup';

if (window.requestIdleCallback) {
    console.time('Idle Init');
    window.requestIdleCallback(() => {
        console.timeEnd('Idle Init');
    });
} else {
    console.log('Idle callback not available, skipping');
}

const g = global as any;
if (!g.Gears) {
    g.Gears = {};
}

import vendor from './modules/vendor';

g.vendor = vendor;
import * as gears from './gears';

g.ggg = gears;
_.merge(g.Gears, gears);

const Gears = _.defaultsDeep(g.Gears, gears, {
    Views: {},
    Models: {},
    Grids: {},
    Templates: {},
    Concerns: {
        Views: {},
        Models: {},
        Grids: {},
        Components: {},
        Templates: {},
        Dolphin: {
            Views: {},
            Models: {},
            Grids: {},
        },
    },
    core: {},
});

g.Gears = Gears;

if (!Gears.user) {
    Object.defineProperty(Gears, 'user', {
        get: () => {
            console.log('Gears.user is deprecated, use gearsState.user.');
            return g.gearsState.user;
        },
    });
}

// import * as browser from "./Browser";
const concerns = require('./concerns');
Gears.core = { gears, concerns };

import 'commonui/DatePicker';

// import sandbox from './gears/Forms/run_sandbox';

// (global as any).sandbox = sandbox;

// Live Reloading
if ((module as any).hot) {
    (module as any).hot.accept();
}
