import { humanize, pluralize, singularize, titleize, underscore } from 'inflection';
import * as _ from 'lodash';
import { IReactComponent } from 'mobx-react';
import * as moment from 'moment-timezone';

import { ConfigurationError } from './Errors';
import { Model } from './GearsModel/Model';
import { defineModel, IModelSpec, IModelType } from './GearsModel/ModelType';
import gearsState from './GearsState';
import { GridController } from './GridController';
import { columnPopoverTemplate, formatLookup, getUniqueElementClass, groupHeaderCount, headerIconTemplate, objectFormat, recentAtIcon } from './helpers';
import { addFaExtras } from './helpers/fa';
import { LookupConfig } from './LookupConfig';

interface GridSpec {}

interface IModelTemplates {
    detail_template: string;
    edit_template: string;
    show_template?: string;
    rollthrough_template: string;
    header_template: string;
    simple_edit_template: string;
}

export interface IGearsGridColumn extends kendo.ui.GridColumn {
    lookup?: LookupConfig;
    boolean?: { true_value: string; false_value: string };
}

interface IGearsKendoGridOptions extends kendo.ui.GridOptions {
    with_inline_edit?: false;
    columns: IGearsGridColumn[];
}

export interface ISubscriptionOptions {
    lookups: boolean;
    actions: boolean;
    insert: boolean;
    update: boolean;
    delete: boolean;
}

export interface IGridConfigOptions {
    multi_select?: boolean;
    grid: IGearsKendoGridOptions;
    data_source: kendo.data.DataSourceOptions;
    has_gears_grid_container: boolean;
    has_saved_filters: boolean;
    has_modal: boolean;
    has_lookups: boolean;
    row_classes: string[];
    refresh_seconds: number;
    view_model: object;
    collapse_groups: boolean;
    include_count: boolean;
    base_name: string;
    title?: string;
    instance_name?: string;
    table_dom_id?: string;
    widget_dom_id?: string;
    subscription: ISubscriptionOptions;
    icon?: string;
    parent_id_field?: string;
    model_reports?: Array<Record<string, any>>;
    showPageId?: false | number | string;
}

function showPageId(): false | string | number {
    if (!$('body').hasClass('show-page')) {
        return false;
    }
    const match = location.pathname.match(/[^\/]+$/);
    return !!match && match[0];
}

const gridDefaults: Partial<IGridConfigOptions> = {
    collapse_groups: true,
    data_source: {
        pageSize: 200,
        schema: {
            aggregates: 'aggregates',
            total: 'total_entries',
        },
        serverAggregates: true,
        serverFiltering: true,
        serverGrouping: false,
        serverPaging: true,
        serverSorting: true,
    },
    grid: {
        allowCopy: true,
        autoBind: false,
        editable: false,
        filterable: {
            extra: true,
            messages: {
                info: '',
            },
            operators: {
                string: {
                    contains: 'Contains',
                    doesnotcontain: 'Does not contain',
                    endswith: 'Ends with',
                    eq: 'Equal to',
                    neq: 'Not equal to',
                    startswith: 'Starts with',
                },
            },
        },
        navigatable: false,
        noRecords: false,
        scrollable: {
            virtual: false,
            // endless: true,
        },
        sortable: {
            allowUnsort: false,
        },
        // filterable: false,
        with_inline_edit: false,
    },
    has_gears_grid_container: false,
    has_lookups: false,
    has_modal: false,
    has_saved_filters: false,
    icon: 'fal fa-cog txt-color-blue',
    multi_select: false,
    refresh_seconds: null,
    row_classes: ['completed', 'cancelled', 'warning', 'ready', 'past-due'],
    subscription: {
        actions: false,
        delete: true,
        insert: true,
        lookups: false,
        update: true,
    },
    view_model: {},
};

const LOOKUP_OPERATORS = {
    eq: 'Equal to',
    neq: 'Not equal to',
};

const DATE_OPERATORS = kendo.ui.FilterMenu.fn.options;

function fullscreenOnly() {
    return !!this.get('fullscreen');
}

function alwaysShow() {
    return true;
}

function excludeFullscreen() {
    return !this.get('fullscreen');
}

export default class GridConfig implements IGridConfigOptions {
    base_name: string;
    human_name?: string;
    table_name?: string;
    title?: string;
    instance_name?: string;
    multi_select: boolean;
    grid: IGearsKendoGridOptions;
    data_source: kendo.data.DataSourceOptions;
    has_gears_grid_container: boolean;
    has_saved_filters: boolean;
    has_modal: boolean;
    has_lookups: boolean;
    row_classes: string[];
    refresh_seconds: number;
    view_model: object;
    collapse_groups: boolean;
    include_count: boolean;
    table_dom_id?: string;
    table_container_dom_id?: string;
    widget_dom_id?: string;
    subscription: ISubscriptionOptions;
    gearsGrid: GridController;
    icon: string;
    parent_id_field?: string;
    model_reports?: object[];
    showPageId: false | number | string;
    private parentGridConfig?: GridConfig;
    private gears_model_name: string;
    private displayName: 'GridConfig';
    private modal_dom_id?: string;
    private modal_rollthrough_dom_id?: string;
    private edit_container_dom_id?: string;

    constructor(options: GridSpec | string, parentGridConfig?: GridConfig) {
        this.parentGridConfig = parentGridConfig;
        if (typeof options === 'string') {
            this.gears_model_name = options;
        } else if (typeof options === 'object') {
            _.merge(this, gridDefaults);
            _.merge(this, _.omitBy(options, _.isNull));
        } else {
            throw new ConfigurationError('Grid Config is missing.');
        }
        if (!this.gears_model_name) {
            throw new ConfigurationError('gears_model_name is missing.');
        }
        if (!this.has_gears_grid_container) {
            console.log('has_gears_grid_container: false is deprecated. Please upgrade ' + this.tableName() + ' Grid to <gears-grid-container/>');
        }
        if (this.include_count == null) {
            this.include_count = !this.parentGridConfig;
        }
        if ((this.include_count as any) === 'false') {
            this.include_count = false;
        }
        if (this.grid.with_inline_edit) {
            console.log('with_inline_edit: true is not supported. Please upgrade ' + this.tableName() + ' Grid.');
        }
        this.showPageId = !parentGridConfig && showPageId();
    }

    private _gearsModel: IModelType<any>;

    get gearsModel(): IModelType<any> {
        let ref$,
            ref1$,
            this$ = this;
        if (this._gearsModel) {
            return this._gearsModel;
        }
        const model: IModelType<any> | IModelSpec = Gears.Models[this.gears_model_name];
        if (!model) {
            throw new ConfigurationError('Model: ' + this.gears_model_name + ' not found.');
        }
        if (_.isFunction(model)) {
            this._gearsModel = model;
            return model;
        }
        if (this.multi_select) {
            this.addMultiSelect();
        }
        _.each(this.grid.columns, column => this$.initColumn(model, column));
        _.remove(this.grid.columns, {
            remove: true,
        });
        if (this.include_count && (ref$ = this.grid.columns) != null && ref$.length) {
            if ((ref1$ = this._firstColumnAdded) != null) {
                ref1$.footerTemplate == null &&
                    (ref1$.footerTemplate = function(it) {
                        if (it.total_entries) {
                            return 'Count: ' + kendo.toString(it.total_entries, 'n0');
                        } else {
                            return '';
                        }
                    });
            }
        }
        this.initGroupHeaderTemplate(model);
        this._gearsModel = defineModel(model, this.gears_model_name);
        return this._gearsModel;
    }

    get editTemplate(): IReactComponent<any> | string {
        return this.edit_template || this.modelTemplates.edit_template || this.editDomId();
    }

    get showTemplate(): IReactComponent<any> | string {
        return this.show_template || this.modelTemplates.show_template || this.edit_template || this.modelTemplates.edit_template || this.editDomId();
    }

    get headerTemplate(): Function | string {
        return this.header_template || this.modelTemplates.header_template || `#${this.gridName()}-gears-grid-header-template`;
    }

    get detailTemplate(): string {
        return this.detail_template || this.modelTemplates.detail_template || this.detailDomId();
    }

    get simpleTemplates(): string {
        return this.modelTemplates.simple_edit_template;
    }

    get modelTemplates(): IModelTemplates {
        return Gears.Templates[this.gears_model_name] || {};
    }

    get rollthroughTemplate(): string {
        const modelTemplates = this.modelTemplates;
        return this.rollthrough_template || (modelTemplates && modelTemplates.rollthrough_template) || this.editRollthroughDomId();
    }

    parentGrid(): GridController {
        if (this.parentGridConfig) {
            return this.parentGridConfig.gearsGrid;
        } else {
            throw new ConfigurationError('parentGrid() called when no parent is present');
        }
    }

    dataSourceConfig() {
        return this.data_source != null ? this.data_source : {};
    }

    gridConfig() {
        return this.grid != null ? this.grid : {};
    }

    modelName(): string {
        return this.gears_model_name;
    }

    viewModelName() {
        return 'children.' + pluralize(this.gears_model_name);
    }

    humanName() {
        return this.human_name || (this.title && singularize(this.title)) || humanize(this.gears_model_name);
    }

    tableName() {
        return this.table_name != null ? this.table_name : underscore(pluralize(this.gears_model_name));
    }

    baseName() {
        return this.base_name != null ? this.base_name : this.tableName();
    }

    uModelName() {
        return underscore(pluralize(this.gears_model_name));
    }

    gridName() {
        return underscore(pluralize(this.gears_model_name));
    }

    instanceName() {
        return this.instance_name != null ? this.instance_name : underscore(this.gears_model_name);
    }

    tableDomId() {
        return this.table_dom_id != null ? this.table_dom_id : `.gears-grid.${underscore(pluralize(this.gears_model_name))}`;
    }

    widgetDomId() {
        return this.widget_dom_id || `#${underscore(pluralize(this.gears_model_name))}-widget`;
    }

    tableContainerDomId() {
        if (this.table_container_dom_id) {
            return this.table_container_dom_id;
        }
        if (this.has_gears_grid_container) {
            return this.gridContainer();
        } else {
            return this.widgetDomId() + ' .widget-body';
        }
    }

    simpleTemplateForField(field: string): string {
        const edit_content = $(this.simpleTemplates).find(`.${field.replace(/[._]/g, '-')}-view`);
        return `<div class='gears-edit-content'>${edit_content.html()}</div>`;
    }

    gridContainer() {
        return this.grid_container != null ? this.grid_container : '#' + this.gridName() + '-grid-container';
    }

    headerContainer() {
        return this.header_container != null ? this.header_container : this.gridContainer() + ' .gears-grid-header';
    }

    tableUrl() {
        return this.table_url != null ? this.table_url : '/' + this.tableName();
    }

    recordUrl(id: string) {
        return this.tableUrl() + '#edit' + id;
    }

    loadSingleUrl(id: string | number) {
        return `${this.tableUrl()}/${id}`;
    }

    detailDomId = function() {
        return this.detail_dom_id != null ? this.detail_dom_id : this.gridName() + '-detail-template';
    };

    filterListDomId() {
        return this.filter_list_dom_id != null ? this.filter_list_dom_id : '#' + underscore(pluralize(this.gears_model_name)) + '-filter-list';
    }

    detailContainerDomId() {
        return this.detail_container_dom_id != null ? this.detail_container_dom_id : underscore(pluralize(this.gears_model_name)) + '-detail-container';
    }

    editDomId() {
        return this.edit_dom_id != null ? this.edit_dom_id : '#' + underscore(pluralize(this.gears_model_name)) + '-edit-template';
    }

    editRollthroughDomId() {
        return this.edit_dom_id != null ? this.edit_dom_id : '#' + underscore(pluralize(this.gears_model_name)) + '-rollthrough-edit-template';
    }

    editContainerDomId(): string {
        if (this.edit_container_dom_id == null) {
            const modal = this.modalDomId();
            this.edit_container_dom_id = '.' + getUniqueElementClass(`${modal} .modal-body`);
        }
        return this.edit_container_dom_id;
    }

    contextmenuDomId(): string {
        return this.contextmenu_dom_id != null ? this.contextmenu_dom_id : '#' + underscore(pluralize(this.gears_model_name)) + '-context-menu';
    }

    modalDomId(): string {
        if (this.modal_dom_id == null) {
            this.modal_dom_id = '.' + getUniqueElementClass(`.${underscore(pluralize(this.gears_model_name))}.gears-modal.widget-modal:not(.rollthrough-modal)`);
        }
        return this.modal_dom_id;
    }

    modalRollthroughDomId() {
        return this.modal_rollthrough_dom_id != null ? this.modal_rollthrough_dom_id : '#' + underscore(pluralize(this.gears_model_name)) + '-rollthrough-modal';
    }

    initColumnForSelf(column) {
        let ref$, model_specific;
        if (column.models.constructor.name === 'String' || column.models.constructor.name === 'Array') {
            column.models = {
                only: column.models,
            };
        }
        if (column.models.only && column.models.only.constructor.name === 'String') {
            column.models.only = [column.models.only];
        }
        if (column.models.exclude && column.models.exclude.constructor.name === 'String') {
            column.models.exclude = [column.models.exclude];
        }
        if (column.models.only != null && _.find(column.models.only, this.modelName())) {
            column.remove = true;
        }
        if (column.models.exclude != null && _.find(column.models.exclude, this.modelName())) {
            column.remove = true;
        }
        model_specific = column.models[this.modelName()];
        if (model_specific != null) {
            if (model_specific.constructor.name === 'exclude' || model_specific.constructor.name === false) {
                return (column.remove = true);
            } else {
                if (model_specific != null) {
                    return _.merge(column, model_specific, true);
                }
            }
        }
    }

    initColumn(model, column) {
        let ref$, ref1$, ref2$, ref3$, ref4$;
        if (column.models != null) {
            this.initColumnForSelf(column);
        }
        if (column.remove) {
            return;
        }
        if (!(this._firstColumnAdded || column.hidden || column.field === '_selected')) {
            column.firstColumn = true;
            this._firstColumnAdded = column;
        }
        column.type == null && (column.type = (ref$ = model.fields[column.field]) != null ? ref$.type : void 8);
        column.attributes == null && (column.attributes = {});
        const valf: Function | null = model[column.field + '_value'];
        if (valf && !column.template) {
            if (!_.isFunction(valf)) {
                console.log('CREATING NON FUNCTION VALF!', valf, model, column);
            }
            column.template = function(it: Model) {
                return (valf as Function).call(it);
            };
        }
        if (column.template) {
            column.template = column.template.bind(this);
        }
        if (column.lookup != null) {
            this.initColumnLookup(model, column);
        }
        switch (column.type) {
            case 'boolean':
                this.initColumnBoolean(model, column);
                break;
            case 'date':
            case 'datetime':
            case 'date_month':
            case 'date_year':
            case 'date_recent':
                this.initColumnDate(model, column);
                break;
            // this.initColumnDateRecent(model, column);
            // break;
            case 'jsonb':
            case 'object':
            case 'json':
                this.initColumnJson(model, column);
                break;
            case 'tags':
                this.initColumnTags(model, column);
                break;
            case 'rating':
                this.initColumnRating(model, column);
                break;
            // case "string":
            // case "text":
            // case "number":
            //     break;
            default:
                if (((ref1$ = column.field) != null ? ref1$.indexOf('.') : void 8) !== -1) {
                    this.initColumnJson(model, column);
                }
        }
        column.popover = (function() {
            let ref$;
            switch (false) {
                case column.attributes['data-rel'] == null:
                    return false;
                case (ref$ = column.popover) !== false && ref$ !== 'false' && ref$ !== null:
                    return false;
                case !column.popover:
                    return true;
                case !column.lookup:
                    return true;
                case (ref$ = column.type) !== 'boolean' && ref$ !== 'json' && ref$ !== 'jsonb' && ref$ !== 'date' && ref$ !== 'datetime':
                    return true;
            }
        })();
        if (!column.title) {
            const fieldNameMatch = column.field.match(/(\w+)\W*$/);
            column.title = fieldNameMatch && fieldNameMatch[1] && titleize(fieldNameMatch[1]);
        }

        column.headerTemplate || (column.headerTemplate = columnPopoverTemplate(model, column));
        if (column.groupHeaderTemplate === '') {
            delete column.groupHeaderTemplate;
        }
        this.initColumnTdPopover(model, column);
        return this.initColumnVisibility(model, column);
    }

    compileColumnTemplates(model, column) {
        let ref$, ref1$;
        if ((ref$ = column.template) != null && ref$.toFunction) {
            column.template = column.template.toFunction();
        }
        if ((ref1$ = column.expandedTemplate) != null && ref1$.toFunction) {
            return (column.expandedTemplate = column.expandedTemplate.toFunction());
        }
    }

    initColumnVisibility(model, column) {
        if (column.visible == null) {
            switch (column.fullscreen) {
                case true:
                case 'only':
                    column.hidden = true;
                    return (column.visible = fullscreenOnly);
                case 'exclude':
                    return (column.visible = excludeFullscreen);
                default:
                    return (column.visible = alwaysShow);
            }
        }
    }

    initColumnJson(model, column) {
        const valfKey: string = `${column.field}_value`;
        let valf: Function = model[valfKey];
        if (valf == null) {
            valf = function jsonDisplay(): string {
                let val;
                val = _.get(this, column.field, '');
                if (val == null) {
                    return '';
                }
                if (typeof val === 'object') {
                    val = objectFormat(val, true);
                }
                return val;
            };
            model[valfKey] = valf;
        }
        valf = model[column.field + '_value'];
        return (
            column.template ||
            (column.template = function(it) {
                return valf.call(it);
            })
        );
    }

    initColumnTags(model, column): void {
        const lookup: string = 'webfront_relations.' + column.field.match(/[^.]+$/)[0];
        const key: string = column.field + '_value';
        if (model[key] == null) {
            model[key] = function(): string {
                let lookupv, res$, i$, len$, v;
                lookupv = _.get(this, lookup);
                if (lookupv != null && lookupv.length) {
                    res$ = [];
                    for (i$ = 0, len$ = lookupv.length; i$ < len$; ++i$) {
                        v = lookupv[i$];
                        res$.push(
                            formatLookup(v, {
                                include_text: false,
                            }),
                        );
                    }
                    return res$.join(' ');
                } else {
                    return _.get(this, column.field) as string;
                }
            };
        }
        const valf: Function = model[key];
        if (!column.template) {
            column.template = (it: any) => valf.call(it);
        }
    }

    initColumnDate(model, column) {
        let valf, expandedf;
        column.fastEdit == null && (column.fastEdit = true);
        column.popover = false;
        const valueKey = column.field + '_value';
        const expandedKey = column.field + '_expanded-value';
        switch (column.type) {
            case 'date':
                column.format == null && (column.format = 'D MMM');
                column.expandedFormat == null && (column.expandedFormat = 'D MMMM YYYY');
                break;
            case 'datetime':
                column.format == null && (column.format = 'D MMM h:mm a');
                column.expandedFormat == null && (column.expandedFormat = 'DD MMMM YYYY <br/>h:mm:ss a');
                break;
            case 'date_month':
                column.format == null && (column.format = 'MMM');
                column.expandedFormat == null && (column.expandedFormat = 'MMMM YYYY');
                break;
            case 'date_year':
                column.format == null && (column.format = 'YYYY');
                column.expandedFormat == null && (column.expandedFormat = 'YYYY');
                break;
            case 'date_recent':
                column.format == null && (column.format = 'YYYY');
                column.expandedFormat == null && (column.expandedFormat = '[<b>]D MMMM YYYY[</b><br/>]h:mma');
                column.popover = true;
                if (column.popoverOptions == null) {
                    column.popoverOptions = {
                        editable: false,
                        hover: true,
                    };
                }
                model[valueKey] = function recentAtValue(): string {
                    const val = _.get(this, column.field);
                    if (!val) {
                        return '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
                    } else {
                        return recentAtIcon(val);
                    }
                };
                if (!column.icon) {
                    column.icon = 'far fa-calendar-alt fa-lg';
                }
                (column.attributes || (column.attributes = {})).class = (column.attributes.class || '') + ' gears-table-icon';
                if (!column.width) {
                    column.width = 45;
                }
        }
        model[valueKey] == null &&
            (model[valueKey] = function dateDisplay() {
                let val;
                val = _.get(this, column.field);
                if (!val) {
                    return '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
                }
                return moment(val).format(column.format);
            });
        model[expandedKey] == null &&
            (model[expandedKey] = function expandedDateDisplay() {
                let val;
                val = _.get(this, column.field);
                if (!val) {
                    return '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
                }
                return moment(val).format(column.expandedFormat);
            });
        valf = model[column.field + '_value'];
        expandedf = model[column.field + '_expanded-value'];
        column.template ||
            (column.template = function(it) {
                return valf.call(it) + '<span class="inline-edit hidden"><i class="fa fa-calendar"/></span>';
            });
        column.expandedTemplate ||
            (column.expandedTemplate = function(it) {
                return expandedf.call(it);
            });
        // console.log("SET FILTERABLE", column.field);
        column.filterable = {
            operators: DATE_OPERATORS,
        };
    }

    initColumnBoolean(model: object, column: IGearsGridColumn): void {
        let include_tooltip, ref$, true_value, false_value, null_value, when_null;
        column.width || (column.width = 40);
        const field_name = column.field;
        include_tooltip = !column.popover;
        (ref$ = column.boolean || {}), (true_value = ref$.true_value), (false_value = ref$.false_value), (null_value = ref$.null_value);
        true_value ||
            (true_value = {
                icon: 'fa-check txt-color-green',
                id: true,
                text: 'true',
            });
        false_value ||
            (false_value = {
                icon: 'fa-circle fa-sm txt-color-grey txt-color-lighten-3',
                id: false,
                text: 'false',
            });
        null_value ||
            (null_value = {
                icon: 'icon-blank',
                id: null,
                text: 'null',
            });
        if (typeof true_value === 'object') {
            true_value = formatLookup(true_value, {
                include_text: false,
                tooltip: include_tooltip,
            });
        }
        if (typeof false_value === 'object') {
            false_value = formatLookup(false_value, {
                include_text: false,
                tooltip: include_tooltip,
            });
        }
        if (typeof null_value === 'object') {
            null_value = formatLookup(null_value, {
                include_text: false,
                tooltip: include_tooltip,
            });
        }
        when_null = model.fields[column.field].when_null;
        column.fastEdit == null && (column.fastEdit = true);
        (column.attributes || (column.attributes = {})).class = (column.attributes.class || '') + ' gears-table-icon';
        return (ref$ = column.template) != null
            ? ref$
            : (column.template = function(it) {
                  let val;
                  val = _.get(it, field_name);
                  val == null && (val = when_null);
                  switch (false) {
                      case val !== true:
                          return true_value;
                      case val !== false:
                          return false_value;
                      default:
                          return null_value;
                  }
              });
    }

    initColumnRating(model, column) {
        column.width || (column.width = 115);
        const field_name = column.field;
        let ulClass = 'rating ';
        if (column.attributes.class) {
            ulClass += column.attributes.class;
        }
        if (!column.template) {
            column.template = function(it: Model) {
                const rating = it.get(field_name);
                if (rating == null) {
                    return '';
                }
                return `<ul class="${ulClass}">${_.repeat('<li/>', rating)}</ul>`;
            };
        }
    }

    initColumnLookup(model, column) {
        // let ref$, x$, extOptions, dataPath, ref1$;
        const lookupConfig = new LookupConfig(column.lookup, column.field);
        column.lookup = lookupConfig;

        column.fastEdit == null && (column.fastEdit = true);
        const lookupOptions = lookupConfig.options;
        if (lookupOptions.include_text == null) {
            lookupOptions.include_text = column.width && column.width < 100 ? 'empty' : true;
        }
        const valF: () => any = model[column.field + '_value'] || lookupConfig.valueFunction;
        const expValF = lookupConfig.expandedValueFunction;
        model[`${column.field}_value`] = valF;
        column.template ||
            (column.template = function(dataItem: object) {
                return valF.call(dataItem);
            });
        column.expandedTemplate ||
            (column.expandedTemplate = function(dataItem: object) {
                return expValF.call(dataItem);
            });
        // column.filterable == null && (column.filterable = {
        //     ui(e: any) {
        //     },
        // });
        const attributesClasses = ['gears-table-icon'];
        if (column.attributes.class) {
            attributesClasses.push(column.attributes.class);
        }
        if (lookupOptions.include_text) {
            attributesClasses.push('include-text');
        }
        if (lookupOptions.ignore_selected) {
            attributesClasses.push('gears-ignore-selected');
        }
        column.attributes.class = attributesClasses.join(' ');

        if (column.filter_menu && column.lookup.type === 'values') {
            column.headerTemplate || (column.headerTemplate = column.title);
            column.headerAttributes == null && (column.headerAttributes = {});
            return _.merge(column.headerAttributes, {
                class: 'gears-filter-header',
            });
        }
        this.initColumnLookupFilter(column);
    }

    initColumnTdPopover(model: any, column: any) {
        let ref$, key$;
        (ref$ = column.attributes || (column.attributes = {}))['data-field'] || (ref$['data-field'] = column.field);
        const config = this;
        const field: any = model.fields[column.field];
        model[(key$ = 'td-popover-config-for-' + column.field)] == null &&
            (model[key$] = function(options) {
                let ref$, icon, ref1$, help_content, rollthrough_content, edit_content, view_content, data_content;
                options == null && (options = {});
                if (column.popoverOptions) {
                    options = _.defaultsDeep(options, column.popoverOptions);
                }
                options.title ||
                    (options.title =
                        (_.isFunction(options.titleTemplate) && options.titleTemplate(this)) ||
                        column.title ||
                        ((ref$ = field.help) != null ? ref$.title : void 8) ||
                        field.label ||
                        titleize(column.field));
                options.trigger = options.hover ? 'hover click' : 'click';
                options.noClickClose = true;
                icon = (options != null ? options.icon : void 8) || (field != null ? field.icon : void 8);
                if (icon) {
                    icon = `<i class='${addFaExtras(icon)}'></i>`;
                }
                if (icon) {
                    options.title = icon + ' ' + options.title;
                }
                if (options.offsetLeft) {
                    options.offsetLeft = parseInt(options.offsetLeft);
                }
                options.editable == null && (options.editable = (ref1$ = column.editable) != null ? ref1$ : true);
                if (options.editable === 'false') {
                    options.editable = false;
                }
                options.fastEdit == null && (options.fastEdit = (ref1$ = column.fastEdit) != null ? ref1$ : true);
                options.autosave == null && (options.autosave = column.lookup || (ref1$ = column.type) === 'boolean' || ref1$ === 'date');
                help_content = options.help || (field != null ? ((ref1$ = field.help) != null ? ref1$.content : void 8) : void 8) || (field != null ? field.help : void 8);
                if (column.rollthrough && options.rollthrough) {
                    rollthrough_content = $('#' + config.gridName() + '-rollthrough-template').html();
                    rollthrough_content = "<div class='rollthrough-popover-content'>\n  " + rollthrough_content + '\n</div>';
                } else {
                    rollthrough_content = '';
                }
                if (options.editable) {
                    edit_content = config.simpleTemplateForField(column.field);
                    // edit_content = $("#" + config.gridName() + "-simple-edit-template ." + column.field.replace(/[._]/g, "-") + "-view");
                    // edit_content = "<div class='gears-edit-content'>" + edit_content.html() + "</div>";
                } else {
                    edit_content = '';
                }
                view_content =
                    (typeof column.expandedTemplate === 'function' ? column.expandedTemplate(this) : void 8) ||
                    (typeof column.template === 'function' ? column.template(this) : void 8) ||
                    _.get(this, column.field) ||
                    '';
                view_content = "<div class='gears-view-content'>" + view_content + '</div>';
                data_content = "<div class='gears-content'>" + edit_content + view_content + rollthrough_content + '</div>';
                options.content = (function() {
                    switch (false) {
                        case !(help_content && data_content):
                            return help_content + '<br/><hr/>' + data_content;
                        case !help_content:
                            return help_content;
                        case !data_content:
                            return data_content;
                        default:
                            return '';
                    }
                })();
                return options;
            });
        if (column.popover) {
            column.attributes['data-rel'] = 'grid-popover';
        }
    }

    initGroupHeaderTemplate(model): void {
        // let ref$, ref1$,  values, ref2$;
        const groupField = _.get(this, ['data_source', 'group', 'field']);
        const column = _.find(this.grid.columns, it => it.field === groupField);
        if (!column) {
            return;
        }
        if (!column.groupHeaderTemplate) {
            const lookup = column.lookup as LookupConfig;
            if (lookup && lookup.type === 'values') {
                column.groupHeaderTemplate = function(it: { value: string | number; order_value: string }) {
                    let value, raw;
                    value = _.find(gearsState.lookups.lists.get(lookup.source), ic => ic.id === it.value || ic.identifier === it.value);
                    if (!value) {
                        value = {
                            icon: 'icon-blank',
                            id: null,
                            text: null,
                        };
                    }
                    if (value.text == null) {
                        value.text = it.order_value || `${it.value}` || 'Unknown';
                    }
                    raw = formatLookup(value, {
                        include_text: true,
                    });
                    raw += groupHeaderCount(it);
                    return raw;
                };
            } else {
                column.groupHeaderTemplate = function(it) {
                    let raw;
                    raw = it.value;
                    raw += groupHeaderCount(it);
                    return raw;
                };
            }
        }
        column.groupHeaderTemplate = _.wrap(
            column.groupHeaderTemplate,
            (old: Function, it: any) => `${old(it) || ''}<span class='group-data' data-group-field='${it.field}' data-group-value='${it.value}'/>`,
        );
    }

    addMultiSelect() {
        return this.grid.columns != null
            ? this.grid.columns.unshift({
                  field: '_selected',
                  filterable: false,
                  headerTemplate: headerIconTemplate({
                      icon: 'fa fa-check-square-o fa-lg',
                      text: 'Selected',
                  }),
                  hidden: true,
                  sortable: false,
                  template(i: any) {
                      return i.gearsSelectedTemplate();
                  },
                  title: 'Selected',
                  visible() {
                      return this.multiSelect != null ? this.multiSelect.enabled : void 8;
                  },
                  width: 45,
              })
            : void 8;
    }

    updateUrl(id: string | number) {
        return `/${this.tableName()}/${id}`;
    }

    webFunctionUrl(id: string | number | null, method: string, methodType?: 'table' | 'record' | 'multiple_records') {
        // console.log("Getting web function url: %o, %o, %o", id, method, methodType);
        if (methodType === 'table' || methodType === 'multiple_records') {
            return `/${this.tableName()}/${method}`;
        } else {
            return `/${this.tableName()}/${id}/${method}`;
        }
    }

    destroyUrl(id: string | number) {
        return this.updateUrl(id);
    }

    lookupsUrl() {
        return `/${this.tableName()}/lookups`;
    }

    parentConfig() {
        return this.parentGridConfig;
    }

    readIdsUrl() {
        return '/' + this.tableName() + '/read_ids';
    }

    readUrl(parent_id: number | string | null) {
        parent_id == null && (parent_id = null);
        const parentConfig = this.parentConfig();
        if (parentConfig != null) {
            return `/${parentConfig.baseName()}/${parent_id}/${this.tableName()}/gears_index`;
        } else {
            return `/${this.tableName()}/gears_index`;
        }
    }

    createUrl(parent_id: number | string | null) {
        parent_id == null && (parent_id = null);
        const parentConfig = this.parentConfig();
        if (parentConfig != null) {
            return `/${parentConfig.baseName()}/${parent_id}/${this.tableName()}`;
        } else {
            return `/${this.tableName()}`;
        }
    }

    private initColumnLookupFilter(column) {
        const { lookup } = column;
        let select2Options: { options: string } | { url: string };
        if (lookup.type === 'values') {
            select2Options = { options: lookup.source };
        } else {
            select2Options = { url: lookup.url };
        }
        column.filterable = {
            extra: true,
            operators: LOOKUP_OPERATORS,
            ui: element => {
                element.width(200);
                element.kendoKendoSelect2(select2Options);
            },
        };
    }
}
