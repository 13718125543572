import Class from 'classnames';
import CircleImage from 'commonui/CircleImage';
import * as gravatar from 'gravatar';
import * as React from 'react';
import styles from './SidebarHeader.scss';

export interface IHeaderData {
    user: string;
    extra: string;
    background: string;
    avatar: string;
    email: string;
}

interface ISidebarHeaderProps {
    header: IHeaderData;
    // user?: string;
    // extra?: string;
    // background?: string;
    // avatar?: string;
    // email?: string;
}

/**
 * Creates the large open background display in sidebar
 */
export default React.memo(function SidebarHeader(props: ISidebarHeaderProps): JSX.Element {
    const { user, extra, background, avatar, email } = props.header;
    return (
        <div className={Class(styles.header)} style={{ background: `url(${background || ''}) center / cover` }}>
            <div>
                <CircleImage src={avatar || gravatar.url(email || '', { d: 'mm' })} size={58} margin={16} />
            </div>
            <div className={Class(styles.backdrop)} />
            <div className={Class(styles.frontdrop)}>
                <span>
                    <span className={Class(styles.name)}>{user}</span>
                    <span className={Class(styles.extra)}>{extra}</span>
                </span>
            </div>
        </div>
    );
});
