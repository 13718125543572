// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._9Le8bg3NYpuwHSLwsLciJ{user-select:none;-moz-user-select:none;position:fixed;width:280px;transform:translateX(-100%);z-index:9;background:#f5f5f5;height:calc(100vh - 64px);overflow-y:auto;transition:transform 300ms;will-change:transform}._9Le8bg3NYpuwHSLwsLciJ._1rij0xZI3rO8A8QpzAfIRs{transform:none;box-shadow:0 4px 4px 0 rgba(0,0,0,0.14),0 6px 2px -4px rgba(0,0,0,0.2),0 2px 10px 0 rgba(0,0,0,0.12)}.jx7X40GB7i7rQ36dEOwii{z-index:8;position:fixed;left:0;top:0;right:0;bottom:0}._3xyRVb5FQtUklu61fwH4Ip{padding:16px}#sidebar-footer{display:none}\n", ""]);
// Exports
exports.locals = {
	"sidebar": "_9Le8bg3NYpuwHSLwsLciJ",
	"open": "_1rij0xZI3rO8A8QpzAfIRs",
	"backdrop": "jx7X40GB7i7rQ36dEOwii",
	"footer": "_3xyRVb5FQtUklu61fwH4Ip"
};
module.exports = exports;
