import ReactDOM from "react-dom";
import Rating from "commonui/StarRating";

export default function setupKendoStarRating() {
    const kendo = window.kendo;
    const ui = kendo.ui;
    const Widget = ui.Widget;
    var StarRating = Widget.extend({
        init: function (element, options) {
            Widget.fn.init.call(this, element, options);
            // console.log("Bound element with options", this, element, options)
            const update = (rate) => {
                this.value(rate);
                this.trigger("change");
            };
            this.props = {
                emptyIcon: this.options.empty,
                filledIcon: this.options.full,
                rating: 0,
                editable: true,
                className: this.options.color,
                onChange: update,
            };
            this.render();
        },

        render: function () {
            const ratingElement = React.createElement(Rating, this.props);
            window.starRating = this.starRating = ReactDOM.render(ratingElement, this.element[0]);
            starRating.setState({
                index: this.props.initialRate
            });
        },

        options: {
            name: "StarRating",
            empty: null,
            full: null,
            editable: true,
            color: null,
        },

        get: function (field) {
            return this.props[field];
        },

        set: function (field, newValue) {
            this.props[field] = newValue;
            this.render();
        },

        value: function (newValue) {
            if (newValue !== undefined) {
                this.set("rating", newValue);
            } else {
                return this.get("rating");
            }
        },
    });
    ui.plugin(StarRating);
}
