const stringOperators = [
    {
        value: "eq",
        label: "Equals",
        icon: "=",
    },
    {
        value: "neq",
        label: "Not Equals",
        icon: "≠",
    },
    {
        value: "isempty",
        label: "Is Empty",
        icon: "",
        disableInput: true,
    },
    {
        value: "isnotempty",
        label: "Is Not Empty",
        icon: "",
        disableInput: true,
    },
    {
        value: "startswith",
        label: "Starts With",
        icon: "",
    },
    {
        value: "endswith",
        label: "Ends with",
        icon: "",
    },
    {
        value: "contains",
        label: "Contains",
        icon: "",
    },
    {
        value: "doesnotcontain",
        label: "Does Not Contain",
        icon: "",
    },
    {
        value: "where",
        label: "Where",
        icon: "",
    },
    {
        value: "isnull",
        label: "Is Null",
        icon: "",
    },
    {
        value: "isnotnull",
        label: "Is Not Null",
        icon: "",
    },
];

export default stringOperators;
