import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Class from 'classnames';
import createCustomDialog from 'commonui/CustomDialog';
import { Menu, MenuItem } from 'commonui/Menu';
import { bind } from 'decko';
import { titleize } from 'inflection';
import * as _ from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';
import GearsState from '../../gears/GearsState/GearsState';
import GearsUser from '../../gears/GearsState/GearsUser';
import ErrorDisplayComponent from '../ErrorDisplayComponent';
import gearsDialog from '../kendo-dialog';
import Sidebar from '../Sidebar';
import AccountSettings from './AccountSettings';
import * as styles from './Header.scss';
import NotificationMenu from './NotificationMenu';

interface IHeaderProps {
    gearsState: GearsState;
}

function BuildInfoItem(props: { title: string; children: React.ReactNode }): React.ReactElement<any> {
    return (
        <div>
            <strong>{props.title}:</strong>
            <span>{props.children}</span>
        </div>
    );
}

function BuildInfo({ gearsState }: IHeaderProps): React.ReactElement<any> | null {
    if (!gearsState.buildInfo) {
        return null;
    }
    const { gears_core_ref, build_time, gears_online_ref, ...remainingBuildInfo } = gearsState.buildInfo;
    return (
        <MenuItem id="admin-info" title="Build Info" icon="fa-info-circle">
            <Menu target="#admin-info" className={Class(styles['header-admin-info'])} mount="left-top">
                <BuildInfoItem title="Gears Core Ref">
                    <a target="_blank" href={`https://gitlab.com/webfrontgears/gears_core/commit/${gears_core_ref}`}>
                        {gears_core_ref || ''}
                    </a>
                </BuildInfoItem>
                <BuildInfoItem title="Gears Online Ref">
                    <a target="_blank" href={`https://gitlab.com/webfrontgears/gears_online/commit/${gears_online_ref}`}>
                        {gears_online_ref || ''}
                    </a>
                </BuildInfoItem>
                <BuildInfoItem title="Build Time">{moment(build_time, 'YYYY-MM-DD HH:mm:SS Z').format('hh:mma Do MMMM YYYY')}</BuildInfoItem>
                {_.map(remainingBuildInfo, (value: string, key: string) => (
                    <BuildInfoItem key={key} title={titleize(key)}>
                        {value || ''}
                    </BuildInfoItem>
                ))}
            </Menu>
        </MenuItem>
    );
}

@observer
export default class Header extends React.Component<IHeaderProps, never> {
    @observable private sidebarOpen: boolean = false;
    @observable private impersonateUsers: GearsUser[] = [];
    private backgroundColour: string;
    private logoURL: string;
    private isAdmin: boolean = false;
    private devMessage: string;
    private deployment: string;

    constructor(props: IHeaderProps) {
        super(props);

        const element = document.getElementById('header-container');
        if (element) {
            this.backgroundColour = element.dataset.colour as string;
            this.logoURL = element.dataset.logo as string;
            this.isAdmin = element.dataset.admin !== '';
            this.devMessage = element.dataset.dev as string;
            this.deployment = element.dataset.deployment as string;
        }

        if (this.isAdmin) {
            $.ajax({
                data: JSON.stringify({
                    filter: {
                        logic: 'and',
                    },
                }),
                dataType: 'json',
                method: 'POST',
                url: '/users/gears_index',
            }).then(results => {
                runInAction(() => {
                    for (const data of results.data) {
                        this.impersonateUsers.push(new GearsUser(data));
                    }
                });
            });
        }
    }

    @bind
    @action
    private onToggleSidebar(): void {
        this.sidebarOpen = !this.sidebarOpen;
    }

    @bind
    private onOpenAccountSettings(): void {
        createCustomDialog(<AccountSettings gearsState={this.props.gearsState} />);
    }

    @bind
    private onDebug(): void {
        $.post(`/users/${global.gearsState.user.id}/enable_query_log`, { duration: 60 * 5 })
            .then(() => global.gears.rootGrid.readLookups())
            .then(() => {
                gearsDialog({
                    buttons: ['OK'],
                    content: 'This mode will last for 5 minutes',
                    title: 'Debug Mode Activated',
                });
            });
    }

    @bind
    private onConsole(): void {
        const search = window.location.search;
        window.location.search = (search ? search + '&' : '?') + 'web_console=true';
    }

    @bind
    private onImpersonate(e: React.MouseEvent<HTMLElement>): void {
        const id = e.currentTarget.dataset.id;
        $.ajax({
            method: 'POST',
            url: '/users/' + id + '/impersonate_user',
        }).then(() => {
            window.location.reload();
        });
    }

    public render(): JSX.Element {
        const userLookup = global.gearsState.user ? global.gearsState.user.lookup : undefined;
        const { menuState, notifications } = this.props.gearsState;
        return (
            <div>
                <header className={Class(styles.header, 'header')} style={{ backgroundColor: this.backgroundColour }}>
                    <ErrorDisplayComponent>
                        <div
                            onClick={this.onToggleSidebar}
                            className={Class(styles.hamburger, 'hamburger', {
                                [styles.active]: this.sidebarOpen,
                                active: this.sidebarOpen,
                            })}
                        >
                            <span />
                        </div>
                        <h1>
                            <a href={'/'}>
                                <img src={this.logoURL} />
                            </a>
                        </h1>
                        {this.devMessage && this.deployment !== 'production' && <div className={Class(styles['dev-message'])}>{this.devMessage}</div>}
                        <div className={Class(styles['action-container'], 'action-container')}>
                            {notifications.active && <NotificationMenu notifications={notifications} className={styles['header-icon']} />}
                            <div id="header-account" className={userLookup.initials_class}>
                                {userLookup.initials}
                            </div>
                            <Menu target="#header-account" mount="left-top">
                                <MenuItem onClick={this.onOpenAccountSettings} title="Settings" icon="fa-cogs" />
                                <MenuItem onClick={window.logout} title="Logout" icon="fa-sign-out" />
                            </Menu>
                            <FontAwesomeIcon id="header-overflow" className={styles['header-icon']} icon="ellipsis-v" />
                            <Menu target="#header-overflow" mount="left-top">
                                <MenuItem onClick={() => window.screenfull.toggle()} title="Fullscreen" icon="fa-expand-arrows" />

                                {this.isAdmin && <MenuItem onClick={() => (window.location.href = '/users/sudo')} title="Admin" icon="fa-user" />}
                                {this.isAdmin && <MenuItem onClick={this.onDebug} title="Debug Mode" icon="fa-bug" />}
                                {this.isAdmin && <MenuItem onClick={this.onConsole} title="Console" icon="fa-code" />}
                                {this.impersonateUsers.length > 0 && (
                                    <MenuItem id="admin-impersonate" title="Impersonate" icon="fa-user-friends">
                                        <Menu target="#admin-impersonate" mount="left-top">
                                            {this.impersonateUsers.map(item => (
                                                <MenuItem key={item.id} onClick={this.onImpersonate} data-id={item.id} title={item.name} />
                                            ))}
                                        </Menu>
                                    </MenuItem>
                                )}
                                <BuildInfo gearsState={this.props.gearsState} />
                            </Menu>
                        </div>
                    </ErrorDisplayComponent>
                </header>
                {menuState.loaded && <Sidebar open={this.sidebarOpen} menuState={menuState} onBackdropClicked={this.onToggleSidebar} />}
            </div>
        );
    }
}
