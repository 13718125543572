import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Class from 'classnames';
import Button from 'commonui/Button';
import { bind } from 'decko';
import { action, extendObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import styles from './FilterBuilder.scss';
import FilterRow from './FilterRow';

interface IFilterGroupProps {
    filter: any;
    filterIndex?: number;
    data: any;
    parentFilter?: any;
}

interface IRenderedFilter {
    index: number;
    filter: any;
}

@observer
export default class FilterGroup extends React.Component<IFilterGroupProps, never> {
    @observable private collapsed: boolean = false;
    @observable private mayDelete: boolean = false;

    constructor(props: IFilterGroupProps) {
        super(props);

        if (!props.filter.logic) {
            extendObservable(props.filter, {
                logic: 'and',
            });
        }
    }

    @bind
    @action
    private toggleCollapsed() {
        this.collapsed = !this.collapsed;
    }

    @bind
    @action
    private addFilter() {
        this.props.filter.filters.push({ field: '', operator: '', value: '' });
    }

    @bind
    @action
    private addFilterGroup() {
        this.props.filter.filters.push({ filters: [{ field: '', operator: '', value: '' }, { field: '', operator: '', value: '' }], logic: 'and' });
    }

    @bind
    @action
    private destroyFilterGroup() {
        this.props.parentFilter.splice(this.props.filterIndex as number, 1);
    }

    @bind
    @action
    private onToggleLogic() {
        if (this.props.filter.logic === 'and') {
            this.props.filter.logic = 'or';
        } else {
            this.props.filter.logic = 'and';
        }
    }

    public render(): JSX.Element {
        let groupEndIndex = -1;
        let containsGroups = false;
        const disableDeletion = this.props.filter.filters.filter(a => !a.filters).length === 1;
        const renderedFilter: IRenderedFilter[] = [];
        this.props.filter.filters.map((item, index) => {
            renderedFilter.push({
                filter: item,
                index,
            });
            return item;
        });
        const forms: JSX.Element[] = renderedFilter
            .sort((a, b) => {
                // Make sure filter groups always appear below this group
                if (a.filter.filters) {
                    return -1;
                }
                if (!a.filter.filters && !b.filter.filters) {
                    return 0;
                }
                return 1;
            })
            .map((item, index) => {
                if (!item.filter.filters) {
                    return (
                        <FilterRow
                            logic={this.props.filter.logic}
                            onToggleLogic={this.onToggleLogic}
                            key={index}
                            filterRow={item.filter}
                            filterRowIndex={item.index}
                            parentFilter={this.props.filter.filters}
                            data={this.props.data}
                            disableDelete={disableDeletion}
                            isFirst={index === 0}
                        />
                    );
                } else {
                    containsGroups = true;
                    groupEndIndex = index;
                    return <FilterGroup parentFilter={this.props.filter.filters} key={index} filter={item.filter} filterIndex={item.index} data={this.props.data} />;
                }
            });

        // Insert the new group button inbetween groups and rules
        forms.splice(
            groupEndIndex + 1,
            0,
            <div key="add-group" className={Class(styles['add-group'], { [styles['is-first']]: groupEndIndex === -1 })}>
                <Button icon="fa-plus-circle" flat title="Add Group" onClick={this.addFilterGroup} />
            </div>,
        );

        const arrow = <FontAwesomeIcon className={styles.collapser} icon={this.collapsed ? 'folder' : 'folder-open'} onClick={this.toggleCollapsed} />;

        return (
            <div className={Class(styles['filter-group'], { [styles['may-delete']]: this.mayDelete, [styles.collapsed]: this.collapsed })}>
                {this.props.parentFilter != null ? arrow : ''}
                <div>
                    {!this.collapsed && this.props.parentFilter && !containsGroups && (
                        <Button
                            icon="fa-minus"
                            title="Delete Group"
                            coloured
                            onMouseOver={action(() => (this.mayDelete = true))}
                            onMouseOut={action(() => (this.mayDelete = false))}
                            className={Class('red', styles['delete-group'])}
                            flat
                            onClick={this.destroyFilterGroup}
                        />
                    )}
                    {!this.collapsed && forms}
                    {!this.collapsed && (
                        <div className={Class(styles['add-rule'])}>
                            <Button icon="fa-plus-circle" flat title="Add Rule" onClick={this.addFilter} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
