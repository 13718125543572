import * as Promise from 'bluebird';
import { bindAll } from 'lodash-decorators';
import { action, computed, observable, ObservableMap, reaction } from 'mobx';
import * as pluralize from 'pluralize';
import { InvalidDataError, SubvertKendo, UserCancelled } from './Errors';
import { standardizeAllDates } from './GearsDataSource';
import { GridController } from './GridController';

declare const Gears: any;

export function addMultiSelect(gridController: any) {
    console.log('addMultiSelect called');
    (global as any).Gears.MultiSelect = MultiSelect;
    new MultiSelect(gridController);
}

interface IMultiSelectGridController extends GridController {
    multiSelect: MultiSelect;
    cancelRollthrough: () => void;
}

@bindAll()
export class MultiSelect extends kendo.data.ObservableObject {
    readonly selectedIds: ObservableMap<boolean> = new ObservableMap();
    @observable enabled: boolean = false;
    @observable private rollthroughRecord?: any;
    private modalRollthroughView?: any;
    private gridController: IMultiSelectGridController;

    constructor(gridController: GridController) {
        super();
        // super.init(this);
        gridController.config.gearsModel.prototype._multiSelect = this;
        // console.log("Setting multiselect on model", gridController.config.gearsModel, gridController.config.gearsModel.prototype, gridController.config.gearsModel.prototype._multiSelect);
        gridController.gearsGrid.table.on('click', 'input.multi-select.checkbox', this.onCheckboxClick);
        gridController.set('multiSelect', this);
        this.gridController = gridController as IMultiSelectGridController;
        _.each(
            [
                'enableMultipleSelectionButton',
                'unselectAllButton',
                'disableMultipleSelectionButton',
                'multiSelectEnabled',
                'multiSelectDisabled',
                'showMultiButton',
                'selectAllInFilter',
                'cancelRollthrough',
                'openRollthrough',
                'saveRollthroughButton',
                'saveRollthrough',
            ],
            (f: string) => ((gridController as any)[f] = (this as any)[f]),
        );
        reaction(
            () => this.enabled,
            () => this.trigger('change', { field: 'enabled' }),
        );
        reaction(
            () => this.hasMultiSelection,
            () => {
                console.log('ms changed', this.hasMultiSelection);
                this.gridController.trigger('change', { field: 'hasMultiSelection' });
            },
        );
        reaction(
            () => this.rollthroughRecord,
            () => this.trigger('change', { field: 'rollthroughRecord' }),
        );
    }

    unselectAllButton() {
        this.selectedIds.clear();
        return this.gridController.refresh();
    }

    disableMultipleSelectionButton() {
        this.selectedIds.clear();
        this.enabled = false;
        this.gridController.gearsGrid.refresh();
    }

    enableMultipleSelectionButton() {
        this.enabled = true;
        this.gridController.dataSource.read();
    }

    multiSelectDisabled(): boolean {
        return !this.enabled;
    }

    showMultiButton() {
        return this.enabled;
    }

    @computed
    get multiSelectionIds(): Array<number | string> {
        return Array.from(this.selectedIds.keys());
    }

    @computed
    get multiSelectionIdsCount(): number {
        return this.selectedIds.size;
    }

    @computed
    get multiSelectionIdsCountFormatted() {
        return pluralize('record', this.multiSelectionIdsCount, true);
    }

    @computed
    get hasMultiSelection() {
        return this.selectedIds.size > 0;
    }

    @action
    setSelection(input: HTMLInputElement) {
        const checked = input.checked;
        const $row = $(input).closest('tr');
        const id = $row.data('id');
        if (checked) {
            this.selectedIds.set(id, true);
            $row.addClass('gears-multi-selected');
        } else {
            this.selectedIds.delete(id);
            $row.removeClass('gears-multi-selected');
        }
        $row.addClass('gears-row');
    }

    onCheckboxClick(input: any) {
        input = input.toElement || input.target;
        console.log('input click', input);
        return this.setSelection(input);
    }

    filterMultiSelection() {
        const ids = this.multiSelectionIds;
        if (!ids.length) {
            return null;
        }
        this.gridController.dataSource.filter({
            field: 'id',
            operator: 'in',
            value: ids,
        });
        return this.gridController.dataSource.read();
    }

    selectAllInFilter() {
        return this.getFilterIds()
            .then(ids => {
                if (ids.length > 50) {
                    return Gears.getConfirmation({
                        content: 'Adding ' + ids.length + ' records to selection.',
                        title: 'Are you sure?',
                    }).then(() => ids);
                } else {
                    return ids;
                }
            })
            .then(ids => {
                console.log('Setting ids', ids);
                const selectedIds = this.gridController.multiSelect.selectedIds;
                _.each(ids, (id: string) => selectedIds.set(id, true));
                this.gridController.multiSelect.set('selectedIds', selectedIds);
                return this.gridController.refresh();
            })
            .catch(UserCancelled, () => console.log('User cancelled'))
            .catch(this.gridController.showDataSourceError);
    }

    multiple_recordsConfirmation(name: string) {
        return {
            message: this.multiSelectionIds.length + ' records are selected.',
            title: 'Are  you want to run ' + name + '?',
        };
    }

    getFilterIds(): Promise<Array<string | number>> {
        const parentGrid = this.gridController.parentGrid;
        const url = this.gridController.config.readUrl(parentGrid != null ? parentGrid.get('selectedRecord.id') : undefined);
        return Promise.resolve(
            $.ajax({
                data: {
                    filter: this.gridController.dataSource.filter(),
                    just_ids: true,
                },
                dataType: 'json',
                type: 'POST',
                url,
            }),
        ).then(data => {
            if (data.error) {
                throw data;
            }
            if (data.length == null) {
                console.log(data);
                throw new InvalidDataError(data);
            }
            return data;
        });
    }

    setupRollthroughModalView() {
        if (this.modalRollthroughView) {
            return;
        }
        try {
            const rollthroughTemplate = this.gridController.config.rollthroughTemplate;
            const modalRollthroughView = new kendo.View(rollthroughTemplate, {
                evalTemplate: true,
                model: this.gridController,
            });
            modalRollthroughView.render($(this.gridController.config.modalRollthroughDomId() + '-body'));
            this.modalRollthroughView = modalRollthroughView;
        } catch (e) {
            console.error('Error rendering rollthrough modal for grid:', this, this.gridController.config.rollthroughTemplate, e);
        }
    }

    openRollthrough(it: any) {
        if (!this.hasMultiSelection) {
            return;
        }
        this.gridController.disableFullscreenForModal();
        this.set('rollthroughRecord', new this.gridController.config.gearsModel({}));
        console.log('Rollthrough', it);
        this.setupRollthroughModalView();
        return $(this.gridController.config.modalRollthroughDomId()).modal({
            backdrop: 'static',
            keyboard: false,
        });
    }

    cancelRollthrough() {
        return $(this.gridController.config.modalRollthroughDomId()).modal('hide');
    }

    cancelRollthroughButton() {
        return this.cancelRollthrough();
    }

    saveRollthrough() {
        const confirmation = 'Are you sure you want to update ' + this.multiSelectionIdsCountFormatted + '?';
        return this.gridController
            .callWebFunctionWithConfirmation(
                'rollthrough',
                {
                    confirmation,
                    dataToSend: standardizeAllDates(this.rollthroughRecord.toJSON()),
                    type: 'multiple_records',
                },
                $(this.gridController.config.modalRollthroughDomId()).find('.save-button'),
            )
            .then(() => {
                this.gridController.cancelRollthrough();
                return this.gridController.refreshButton();
            });
    }

    saveRollthroughButton() {
        return this.saveRollthrough();
    }

    deleteAll(): Promise<any> {
        let confirmation;
        confirmation = 'Are you sure you want to delete ' + this.multiSelectionIdsCountFormatted() + '?';
        return this.gridController
            .callWebFunctionWithConfirmation('delete_all', {
                confirmation,
                type: 'multiple_records',
            })
            .then(() => this.gridController.refreshButton());
    }

    deleteAllButton() {
        return this.deleteAll();
    }
}
